import { element } from 'protractor';
import { ContratoAhorrent, InspeccionesDetalladasAhorrent } from './ContratoAhorrent';
import { HojaMembretada } from './hojaMembretada';
import { MembreteEmpresa } from './membreteEmpresa';
import * as moment from 'moment';
moment.locale('es');
import * as momentTimezone from 'moment-timezone';
import { Usuario } from './usuario';
import { APIUrls } from '../api/apiUrls';
import { Rol } from './rol';
import { Empresa } from './empresa';
import { Cliente } from './cliente';
import { Producto } from './producto';
import { UsuarioEmpresa } from './usuarioEmpresa';
import { Venta } from './venta';
import { FormularioFEIC } from './formularioFEIC';
import { ListaNegra } from './listaNegra';
import { AjusteGeneral } from './ajusteGeneral';
import { ContenidoMatriz } from './contenidoMatriz';
import { MarcaVehiculo } from './marcaVehiculo';
import { LineaVehiculo } from './lineaVehiculo';
import { AjusteAvaluo } from './ajusteAvaluo';
import { OtroDescuento } from './OtroDescuento';
import { Avaluo } from './avaluo';
import { HistorialHojaMembretada } from './historialHojaMembretada';
import { ReporteHM } from './ReporteHM';
import { CambioEstadoAvaluo } from './cambioEstadoAvaluo';
import { Manual } from './manual';
import { Unidad } from './unidad';
import { ClienteAhorrent } from './clienteAhorrent';
import { ConfiguracionAhorrent } from './configuracionAhorrent';
import { DocumentoCliente } from './documentoCliente';
import { SeguimientoCliente } from './seguimientoCliente1';
import { CambioEstadoUnidad } from './cambioEstadoUnidad';
import { ServicioUnidad } from './servicioUnidad';
import { GastoServicio } from './gastoServicio';
import { CampaniaPreventiva } from './CampaniaPreventiva';
import { VehiculoCampania } from './VehiculoCampania';
import { ReservacionCampania } from './ReservacionCampania';
import { ContenidoFormularioAhorrent } from './contenidoFormularioAhorrent';
import { Concesionario } from './concesionario';
import { Cita, HorarioCita } from './cita';
import { Servicio } from './servicio';
import { Repuesto } from './repuesto';
import { Version } from './version';
import { Kilometraje } from './Kilometraje';
import { RepuestoVersion } from './RepuestoVersion';
import { ServicioVersion } from './ServicioVersion';
import { CambioEstadoContratoAhorrent } from './cambioEstadoContratoAhorrent';
import { ServicioPendienteUnidad } from './ServicioPendienteUnidad';
import { VersionLineaSOB } from './versionLineaSOB';
import { VehiculoSOB } from './vehiculoSOB';
import { ConfiguracionLanding } from './configuracionLanding';
import { MarcaAhorrent } from './marcaAhorrent';
import { LineaAhorrent } from './lineaAhorrent';
import { SolicitudPruebaManejo } from './SolicitudPruebaManejo';
import { ConfiguracionDigitalizacion } from './configuracionDigitalizacion';
import { CambioEstadoSolicitud } from './CambioEstadoSolicitud';
import { DocumentoSolicitud } from './DocumentoSolicitud';
import { VehiculoDemo } from './VehiculoDemo';
import { ClienteBoleta } from './ClienteBoleta';
import { ClientePlanMantenimiento } from './ClientePlanMantenimiento';
import { VehiculoPlanMantenimiento } from './vehiculoPlanMantenimiento';
import { PlanDeMantenimiento } from './PlanDeMantenimiento';
import { ItemPlanDeMantenimiento } from './ItemPlanDeMantenimiento';
import { Contratacion } from './Contratacion';
import { OrdenTrabajoPlanMantenimiento } from './OrdenTrabajoPlanMantenimiento';
import { DocumentoContratacion } from './DocumentoContratacion';
import { ConfiguracionPlanMantenimiento } from './configuracionPlanMantenimiento';
import { ConfiguracionPDI, NotificacionAutorizacionSolicitud } from './configuracionPDI';
import { InspeccionesDetalladasPDI, SolicitudPDI } from './solicitudPDI';
import { CambioEstadoSolicitudPDI } from './cambioEstadoSolicitudPDI';
import { DanioSolicitudPDI } from './DanioSolicitudPDI';
import { DanioAhorrent } from './DanioAhorrent';
import { DatoVenta } from './DatoVenta';
import { ClientePresupuesto } from './ClientePresupuesto';
import { ConfiguracionPresupuesto } from './configuracionPresupuesto';
import { Presupuesto } from './presupuesto';
import { CambioEstadoPresupuesto } from './cambioEstadoPresupuesto';
import { PuntoPresupuesto } from './PuntoPresupuesto';
import { ItemPuntoPresupuesto } from './ItemPuntoPresupuesto';
import { RepuestoItemPuntoPresupuesto } from './RepuestoItemPuntoPresupuesto';
import { HistoricoItemPresupuesto } from './HistoricoItemPresupuesto';
import { ConfiguracionLead } from './ConfiguracionLead';
import { ProspectoLead } from './ProspectoLead';
import { CambioEstadoProspectoLead } from './CambioEstadoProspectoLead';
import { SeguimientoProspectoLead } from './SeguimientoProspectoLead';
import { ImagenUnidad } from './ImagenUnidad';
import { FacturaSV } from './FacturaSV';
import { DetalleFacturaSV } from './DetalleFacturaSV';
import { ConfiguracionFacturaSV } from './ConfiguracionFacturaSV';
import { ValorFacturaEspecial } from './ValorFacturaEspecial';
import { TransactionFEL } from './TransactionFEL';
import { DocumentoUnidad } from './DocumentoUnidad';
import { MultaAhorrent } from './MultaAhorrent';
import { CambioUbicacionUnidad } from './CambioUbicacionUnidad';
import { ConfiguracionRequisicion } from './ConfiguracionRequisicion';
import { CambioEstadoRequisicion } from './CambioEstadoRequisicion';
import { Requisicion } from './Requisicion';
import { DetalleRequisicion } from './DetalleRequisicion';
import { ObservacionClienteAhorrent } from './ObservacionClienteAhorrent';
import { ReclamoRentaClienteAhorrent } from './ReclamoRentaClienteAhorrent';
import { ReclamoGeneralClienteAhorrent } from './ReclamoGeneralClienteAhorrent';
import { SolicitudTraspaso } from './solicitudTraspaso';
import { CambioEstadoSolicitudTraspaso } from './CambioEstadoSolicitudTraspaso';
import { OrdenTallerKanboard } from './OrdenTallerKanboard';
import { ConfiguracionOrdenTallerKanboard } from './ConfiguracionOrdenTallerKanboard';
import { CambioEstadoOrdenTallerKanboard } from './CambioEstadoOrdenTallerKanboard';
import { EncuestaClienteAhorrent } from './EncuestaClienteAhorrent';
import { ConfiguracionDigitalizacionTraspasos } from './ConfiguracionDigitalizacionTraspasos';
import { HistorialFirmaPromesa } from './HistorialFirmaPromesa';
import { HistorialFirmaTraspaso } from './HistorialFirmaTraspaso';
import { CambioFechaContratoAhorrent } from './CambioFechaContratoAhorrent';
import { PendienteUnidad } from './pendienteUnidad';
import { ConfiguracionBestune } from './ConfiguracionBestune';
import { RepuestoBestune } from './RepuestoBestune';
import { CajeroFacturaSV } from './CajeroFacturaSV';
import { LavadoEspecial } from './LavadoEspecial';

export class JSONConverters {
    public apiURLs = new APIUrls();

    public usuarioDeJSON = (json: any): Usuario => {
        if (!json) return null;
        var registro: Usuario;
        registro = new Usuario(
            json.id,
            json.nombres,
            json.apellidos,
            json.email,
            json.email2,
            json.telefono,
            json.telefono2,
            json.idGoogle,
            json.activo,
            json.isDeleted,
            json.atiendeCitas,
            json.horaInicioAtencion,
            json.horaFinAtencion,
            json.diasAtencion,
            json.duracionCitas,
            json.horaInicioAlmuerzo,
            json.horaFinAlmuerzo,
            json.inicioVacaciones,
            json.finVacaciones,
            json.DPI,
            json.ConcesionarioId,
        );

        if (json.diasAtencion) {
            registro.diasAtencionArray = json.diasAtencion.split(',');
        }

        registro.nombreCompleto = '';
        if (registro.nombres) registro.nombreCompleto += registro.nombres;
        if (registro.apellidos) {
            if (registro.nombreCompleto != null && registro.nombreCompleto.trim() != '') registro.nombreCompleto += ' ';
            registro.nombreCompleto += registro.apellidos;
        }

        if (json.UsuarioEmpresas && json.UsuarioEmpresas.length > 0) {
            var usuarioEmpresa = json.UsuarioEmpresas[0];
            registro.RolId = usuarioEmpresa.RolId;
            registro.relacionEmpresa = this.usuarioEmpresaDeJSON(usuarioEmpresa);
            if (usuarioEmpresa.Rol) registro.rol = this.rolDeJSON(usuarioEmpresa.Rol);
            if (usuarioEmpresa.Empresa) registro.empresa = this.empresaDeJSON(usuarioEmpresa.Empresa);
            registro.permisosAdicionales = usuarioEmpresa.permisosAdicionales;
            registro.bloqueosAdicionales = usuarioEmpresa.bloqueosAdicionales;
        }

        if (json.empresas) {
            registro.empresas = [];
            for (let i = 0; i < json.empresas.length; i++) {
                const element = json.empresas[i];
                registro.empresas.push(this.empresaDeJSON(element));
            }
        }

        if (json.inicioVacaciones) {
            registro.inicioVacacionesFormato = this.convertirFechaATimezone(json.inicioVacaciones, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
            registro.inicioVacacionesDate = this.convertirFecha(json.inicioVacaciones, 'YYYY-MM-DD', 'date');
        }

        if (json.finVacaciones) {
            registro.finVacacionesFormato = this.convertirFechaATimezone(json.finVacaciones, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
            registro.finVacacionesDate = this.convertirFecha(json.finVacaciones, 'YYYY-MM-DD', 'date');
        }

        if (!registro.permisosAdicionales && json.permisosAdicionales) registro.permisosAdicionales = json.permisosAdicionales;

        return registro;
    }

    public rolDeJSON = (json: any): Rol => {
        if (!json) return null;
        var registro = new Rol(
            json.id,
            json.nombre,
            json.tipo,
            json.descripcion,
            json.permisos,
            json.bloqueos,
            json.mostrar,
        );

        return registro;
    }

    public empresaDeJSON = (json: any): Empresa => {
        if (!json) return null;
        var registro = new Empresa(
            json.id,
            json.nombre,
            json.logo,
            json.descripcion,
            json.nit,
            json.nombreLegal,
            json.activo,
            json.isDeleted,
            json.prefijo,
            json.moduloAhorrent,
        );

        return registro;
    }

    public clienteDeJSON = (json: any): Cliente => {
        if (!json) return null;
        var registro = new Cliente(
            json.id,
            json.codigo,
            json.primerApellido,
            json.segundoApellido,
            json.apellidoCasada,
            json.primerNombre,
            json.segundoNombre,
            json.otrosNombres,
            json.otros,
            json.fechaNacimiento,
            json.paisNacimiento,
            json.departamentoNacimiento,
            json.municipioNacimiento,
            json.condicionMigratoria,
            json.otraCondicionMigratoria,
            json.edad,
            json.nacionalidad,
            json.otraNacionalidad,
            json.sexo,
            json.estadoCivil,
            json.profesion,
            json.documentoIdentificacion,
            json.numeroIdentificacion,
            json.emisionPasaporte,
            json.nit,
            json.telefono1,
            json.telefono2,
            json.correoElectronico,
            json.direccionResidencia,
            json.paisResidencia,
            json.departamentoResidencia,
            json.municipioResidencia,
            json.isDeleted,
            json.nombreCompleto,
            //Relaciones
            json.EmpresaId,
        );

        registro.nombreCompleto = (json.primerNombre ? json.primerNombre : '') + ' ' + (json.segundoNombre ? json.segundoNombre : '') + ' ' + (json.primerApellido ? json.primerApellido : '') + ' ' + (json.segundoApellido ? json.segundoApellido : '') + ' ';
        registro.nombreCompletoNit = (json.primerNombre ? json.primerNombre : '') + ' ' + (json.segundoNombre ? json.segundoNombre : '') + ' ' + (json.primerApellido ? json.primerApellido : '') + ' ' + (json.segundoApellido ? json.segundoApellido : '') + ' (' + (json.nit ? json.nit : '') + ')';
        registro.nombreCompletoNitDireccion = (json.primerNombre ? json.primerNombre : '') + ' ' + (json.segundoNombre ? json.segundoNombre : '') + ' ' + (json.primerApellido ? json.primerApellido : '') + ' ' + (json.segundoApellido ? json.segundoApellido : '') + ' (' + (json.nit ? json.nit : '') + ') - (' + (json.direccionResidencia ? json.direccionResidencia : '') + ')';
        registro.formulariosFEIC = [];


        if (json.Empresa) {
            registro.empresa = this.empresaDeJSON(json.Empresa);
        }

        if (json.fechaNacimiento) {
            registro.fechaNacimientoFormato = this.convertirFechaATimezone(json.fechaNacimiento, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
            registro.fechaNacimientoDate = this.convertirFecha(json.fechaNacimiento, 'YYYY-MM-DD', 'date');
        }

        if (json.FormularioFEICs && Array.isArray(json.FormularioFEICs)) {
            for (let formulario of json.FormularioFEICs) {
                registro.formulariosFEIC.push(this.formularioFEICDeJSON(formulario));
            }
        }

        return registro;
    }

    public ventaDeJSON = (json: any): Venta => {
        try {
            if (!json) return null;
            var registro = new Venta(
                json.id,
                json.fecha,
                json.total,
                json.observaciones,
                json.isDeleted,
                json.numerosInventario,
                json.nitsClientes,
                json.nombresProductos,

                json.VendedorId,
                json.EmpresaId,
                json.nombre,
                json.UsuarioId,
            );

            if (json.fecha) {
                registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY');
                registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
            }

            if (json.FormularioFEICs) {
                registro.formulariosFEIC = [];
                for (let i = 0; i < json.FormularioFEICs.length; i++) {
                    var element = json.FormularioFEICs[i];
                    var formulario = this.formularioFEICDeJSON(element);
                    registro.formulariosFEIC.push(formulario);
                }
            }

            if (json.Empresa) {
                registro.empresa = this.empresaDeJSON(json.Empresa);
            }

            if (json.Vendedor) {
                registro.vendedor = this.usuarioDeJSON(json.Vendedor);
            }

            return registro;
        } catch (error) {
            return null;
        }
    }

    public formularioFEICDeJSON = (json: any): FormularioFEIC => {
        try {
            if (!json) return null;
            var registro = new FormularioFEIC(
                json.id,
                json.nombre,
                json.tipo,
                json.contenido,
                json.cerrado,
                json.fecha,
                json.total,
                json.impresiones,
                json.actual,
                json.observaciones,
                json.completado,
                json.isDeleted,
                json.factorRiesgoProducto,
                json.factorRiesgoCliente,
                json.factorRiesgoCanal,
                json.factorRiesgoUbicacion,
                json.factorRiesgoTransaccion,
                json.factorRiesgoEstructural,
                json.factorRiesgoPromedio,
                json.factorRiesgoControl,
                json.factorRiesgoResidual,
                json.numeroInventario,
                json.nombresProductos,
                json.motivoEliminarcion,

                json.ClienteId,
                json.VentaId,
                json.EmpresaId,
                json.ListaNegraId,
                json.UsuarioId,
            );

            registro.nombreFormulario = 'Formulario FEIC #' + registro.id;
            registro.listasNegras = [];

            if (json.tipo) {
                switch (json.tipo) {
                    case 'individual': registro.tipoMostrar = 'Individual'; break;
                    case 'juridico': registro.tipoMostrar = 'Jurídico'; break;
                }
            }

            if (json.contenido) {
                registro.contenidoFEIC = JSON.parse(json.contenido);
                if (registro.contenidoFEIC.fechaNacimiento) {
                    registro.contenidoFEIC.fechaNacimientoFormato = this.convertirFechaATimezone(registro.contenidoFEIC.fechaNacimiento, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
                    registro.contenidoFEIC.fechaNacimientoDate = this.convertirFecha(registro.contenidoFEIC.fechaNacimiento, 'YYYY-MM-DD', 'date');
                }
                if (registro.contenidoFEIC.fechaInicialRepLegal) {
                    registro.contenidoFEIC.fechaInicialRepLegalFormato = this.convertirFechaATimezone(registro.contenidoFEIC.fechaInicialRepLegal, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
                    registro.contenidoFEIC.fechaInicialRepLegalDate = this.convertirFecha(registro.contenidoFEIC.fechaInicialRepLegal, 'YYYY-MM-DD', 'date');
                }
                if (registro.contenidoFEIC.fechaFinalRepLegal) {
                    registro.contenidoFEIC.fechaFinalRepLegalFormato = this.convertirFechaATimezone(registro.contenidoFEIC.fechaFinalRepLegal, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
                    registro.contenidoFEIC.fechaFinalRepLegalDate = this.convertirFecha(registro.contenidoFEIC.fechaFinalRepLegal, 'YYYY-MM-DD', 'date');
                }
            }

            if (json.fecha) {
                registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY');
                registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
            }

            if (json.Empresa) {
                registro.empresa = this.empresaDeJSON(json.Empresa);
                if (registro.empresa.prefijo) {
                    registro.idPrefijo = registro.empresa.prefijo + '-' + registro.id;
                } else registro.idPrefijo = '' + registro.id;
            }

            if (json.Ventum) {
                registro.venta = this.ventaDeJSON(json.Ventum);
            }

            if (json.Usuario) {
                registro.vendedor = this.usuarioDeJSON(json.Usuario);
            }

            if (json.Cliente) {
                registro.cliente = this.clienteDeJSON(json.Cliente);
            }

            if (json.ListaNegra) {
                let listaNegra = this.listaNegraDeJSON(json.ListaNegra);
                listaNegra.check = true;
                registro.listasNegras.push(listaNegra);
            }

            return registro;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public ajusteGeneralDeJSON = (json: any): AjusteGeneral => {
        try {
            if (!json) return null;
            var registro = new AjusteGeneral(
                json.id,
                json.ajustesMatrizRiesgo,
                json.camposAdicionalesFEIC,
                json.EmpresaId,
            );

            if (json.ajustesMatrizRiesgo) {
                let contenido: ContenidoMatriz = JSON.parse(json.ajustesMatrizRiesgo);
                registro.contenido = contenido;
            }

            if(json.camposAdicionalesFEIC) {
                registro.camposAdicionalesFEICArray = JSON.parse(json.camposAdicionalesFEIC);
            }

            return registro;
        } catch (error) {
            return null;
        }
    }

    public manualDeJSON = (json: any): Manual => {
        try {
            if (!json) return null;
            var registro = new Manual(
                json.id,
                json.contenido
            );

            if (json.contenido) {
                let contenidoModel = JSON.parse(json.contenido);
                registro.contenidoModel = contenidoModel;
            }
            return registro;
        } catch (error) {
            return null;
        }
    }

    public productoDeJSON = (json: any): Producto => {
        if (!json) return null;
        var registro = new Producto(
            json.id,
            json.tipo,
            json.nombre,
            json.descripcion,
            json.moneda,
            json.valorUnitario,
            json.identificadorProducto,
            json.claseVehiculo,
            json.valorMonetario,
            json.isDeleted,
            json.marca,
            json.linea,
            json.modelo,
            json.feic,
            json.avaluos,

            //Relaciones
            json.EmpresaId,
        );

        if (json.Empresa) {
            registro.empresa = this.empresaDeJSON(json.Empresa);
        }

        return registro;
    }

    public usuarioEmpresaDeJSON = (json: any): UsuarioEmpresa => {
        if (!json) return null;
        var registro = new UsuarioEmpresa(
            json.id,
            json.permisosAdicionales,
            json.bloqueosAdicionales,
            json.permisosMembretes,
            json.idsUsuariosAprobacionHojas,
            json.sedesAprobacionDigitalizacion,
            json.activo,
            json.isDeleted,
            json.UsuarioId,
            json.RolId,
            json.EmpresaId
        );

        if (json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.Rol) {
            registro.rol = this.rolDeJSON(json.Rol);
        }

        if (json.Empresa) {
            registro.empresa = this.empresaDeJSON(json.Empresa);
        }

        return registro;
    }

    public listaNegraDeJSON = (json: any): ListaNegra => {
        if (!json) return null;
        var registro = new ListaNegra(
            json.id,
            json.nombre,
            json.tipo,
            json.observaciones,
            json.rti,
            json.rts,
            json.ministerioPublico,
            json.eliminado,
            json.empresa,
        );

        return registro;
    }

    // * * * Membretes * * *
    public membreteEmpresaDeJSON = (json: any): MembreteEmpresa => {
        if (!json) return null;
        var registro = new MembreteEmpresa(
            json.id,
            json.nombre,
            json.descripcion,
            json.margenArriba,
            json.margenIzquierdo,
            json.margenAbajo,
            json.margenDerecho,
            json.margenInterno,
            json.correlativo,
            json.imagenHeader,
            json.imagenFooter,
            json.activo,
            json.eliminado,
            json.bloqueInformacion,
            json.bloqueDetalles,
            json.bloqueEnvio,
            json.bloqueReferencias,
            json.logoPromocional,
            json.logoHeader,
            json.nombreCompania,
            json.colorTitulo,
            json.EmpresaId,
        );

        if (json.Empresa) {
            registro.empresa = this.empresaDeJSON(json.Empresa);
        }

        return registro;
    }

    public hojaMembretadaDeJSON = (json: any): HojaMembretada => {
        if (!json) return null;
        var registro = new HojaMembretada(
            json.id,
            json.fechaCreacion,
            json.fechaActualizacion,
            json.titulo,
            json.descripcion,
            json.estado,
            json.contenido,
            json.descargas,
            json.eliminado,
            json.variables,
            json.casosDeUso,
            json.observacionesSolicitud,
            json.fechaCarta,

            json.UsuarioId,
            json.MembreteEmpresaId,
            json.EmpresaId,
        );

        registro.variablesModel = [];
        registro.casosDeUsoModel = [];

        if (json.estado) {
            let index = this.indexOf(this.apiURLs.estadoHojaMembrete, 'id', json.estado);
            registro.estadoFormat = this.apiURLs.estadoHojaMembrete[index].nombre;
        }

        if (json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.MembreteEmpresa) {
            registro.membrete = this.membreteEmpresaDeJSON(json.MembreteEmpresa);
        }

        if (json.Empresa) {
            registro.empresa = this.empresaDeJSON(json.Empresa);
        }

        if (json.fechaCreacion) {
            registro.fechaCreacionFormato = this.convertirFecha(json.fechaCreacion, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY');
            registro.fechaCreacionDate = this.convertirFecha(json.fechaCreacion, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaActualizacion) {
            registro.fechaActualizacionFormato = this.convertirFecha(json.fechaActualizacion, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY');
            registro.fechaActualizacionDate = this.convertirFecha(json.fechaActualizacion, 'YYYY-MM-DD', 'date');
        }

        if (json.variables) {
            registro.variablesModel = JSON.parse(json.variables);
        }

        if (json.casosDeUso) {
            registro.casosDeUsoModel = JSON.parse(json.casosDeUso);
        }

        return registro;
    }

    public historialHojaMembretadaDeJSON = (json: any): HistorialHojaMembretada => {
        if (!json) return null;
        var registro = new HistorialHojaMembretada(
            json.id,
            json.fecha,
            json.tipo,
            json.observaciones,
            json.HojaMembretadaId,
            json.UsuarioId,
            json.EmpresaId,
        );

        if (json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.HojaMembretada) {
            registro.hojaMembretada = this.hojaMembretadaDeJSON(json.HojaMembretada);
        }

        if (json.Empresa) {
            registro.empresa = this.empresaDeJSON(json.Empresa);
        }

        if (json.fecha) {
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if (json.tipo) {
            var index = this.indexOf(this.apiURLs.tiposHistorial, 'id', json.tipo);
            registro.tipoFormato = this.apiURLs.tiposHistorial[index].nombre;
        }

        return registro;
    }

    // * * * Avaluos * * * //
    public marcaVehiculoDeJSON = (json: any): MarcaVehiculo => {
        if (!json) return null;
        var registro = new MarcaVehiculo(
            json.id,
            json.nombre,
            json.descripcion,
            json.isDeleted,
            json.isVW
        );
        return registro;
    }

    public lineaVehiculoDeJSON = (json: any): LineaVehiculo => {
        if (!json) return null;
        var registro = new LineaVehiculo(
            json.id,
            json.nombre,
            json.descripcion,
            json.modelos,
            json.usarTransparenciaPrecios,
            json.isDeleted,
            json.imagen,
            json.noMostrarLandingPage,
        );

        if (json.modelos) {
            registro.listadoModelos = JSON.parse(json.modelos);
        }

        if (json.MarcaVehiculo) {
            registro.vehiculo = this.marcaVehiculoDeJSON(json.MarcaVehiculo);
        }

        return registro;
    }

    // * * * Ahorrent * * * //
    public marcaAhorrentDeJSON = (json: any): MarcaAhorrent => {
        if (!json) return null;
        var registro = new MarcaAhorrent(
            json.id,
            json.nombre,
            json.descripcion,
            json.isDeleted,
            json.isVW
        );
        return registro;
    }

    public lineaAhorrentDeJSON = (json: any): LineaAhorrent => {
        if (!json) return null;
        var registro = new LineaAhorrent(
            json.id,
            json.nombre,
            json.descripcion,
            json.modelos,
            json.usarTransparenciaPrecios,
            json.isDeleted,
            json.imagen,
        );

        if (json.modelos) {
            registro.listadoModelos = JSON.parse(json.modelos);
        }

        if (json.MarcaAhorrent) {
            registro.vehiculo = this.marcaAhorrentDeJSON(json.MarcaAhorrent);
        }
        return registro;
    }

    // * * * Ajustes Avalúo * * * //
    public ajusteAvaluoDeJSON = (json: any): AjusteAvaluo => {
        if (!json) return null;
        var registro = new AjusteAvaluo(
            json.id,
            json.porcentajePrimerAnio,
            json.porcentajeAnioExtra,
            json.porcentajePrimerAnioVW,
            json.porcentajeAnioExtraVW,
            json.iva,
            json.utilidad,
            json.kilometrajeAnual,
            json.porcentajeKilometrajeAnualExtra,
            json.porcentajeKilometrajeAnualExtraVW,
            json.cantidadKmAceptadosAnio,
            json.vobo,
            json.agencias,
            json.carrocerias,
            json.correosAgencia,
        )

        return registro;
    }

    // * * * Otro Descuento * * * //
    public otroDescuentoDeJSON = (json: any): OtroDescuento => {
        if (!json) return null;
        var registro = new OtroDescuento(
            json.id,
            json.nombre,
            json.descuento,
            json.tipo,
            json.tipoAplicacion
        );
        return registro;
    }

    public reporteHMDeJSON = (json: any): ReporteHM => {
        if (!json) return null;
        var registro = new ReporteHM(
            json.nombreUsuario,
            json.cantidadHM,
            json.descargasTotales,
            json.cantidadSolicitudes
        )
        return registro;
    }

    // * * * Avaluo * * * 
    public avaluoDeJSON = (json: any): Avaluo => {
        try {
            if (!json) return null;
            var registro = new Avaluo(
                json.id,
                json.estado,
                json.tipo,
                json.fechaSolicitud,
                json.fechaAvaluo,
                json.fechaAprobacion,
                json.fechaConfirmado,
                json.fechaRechazado,
                json.fechaRecibido,
                json.numeroInventario,
                json.vehiculoRecibido,
                json.vehiculoIngresado,
                json.codigo,
                json.primerApellido,
                json.segundoApellido,
                json.apellidoCasada,
                json.primerNombre,
                json.segundoNombre,
                json.otrosNombres,
                json.nit,
                json.color,
                json.modelo,
                json.kilometraje,
                json.precioLista,
                json.devaluacionEstandar,
                json.otrosDescuentos,
                json.precioFinal,
                json.precioFinalEstimado,
                json.telefono,
                json.placa,
                json.cc,
                json.numeroMotor,
                json.transmision,
                json.es4x4,
                json.vehiculoInteres,
                json.chasis,
                json.referido,
                json.listadoDevoluciones,
                json.listadoDescuentos,
                json.inspeccionesVisuales,
                json.inspeccionGrafica,
                json.MarcaVehiculoId,
                json.LineaVehiculoId,
                json.ClienteId,
                json.EmpresaId,
                json.UsuarioVendedorId,
                json.UsuarioAvaluadorId,
                json.UsuarioGerenciaId,
                json.otrasInspeeciones,
                json.descuentoKilometraje,
                json.utilidad,
                json.iva,
                json.descuentoInspeccionGrafica,
                json.observacionesPrecioAprobado,
                json.descuentosAdicionales,
                json.recargosAdicionales,
                json.descuentoInspeccionMecanicaVisual,
                json.observacionesDescuento,
                json.ProductoId,
                json.es4x2,
                json.AWD,
                json.tipoAsiento,
                json.colorAsiento,
                json.aplicarSegundoIVA,
                json.segundoIVA,
                json.agencia,
                json.nombreTarjetaCirculacion,
                json.cambiosLinea,
                json.descuentoCambioLinea,
                json.correo,
                json.esMarcaPremium,
                json.carroceria,
                json.fechaDescartado,
                json.fechaAprobadoNoEntregado,
            );

            if (json.precioFinal && json.precioFinalEstimado) {
                registro.diferenciaEstimado = json.precioFinal - json.precioFinalEstimado;
            }

            if (json.fechaSolicitud) {
                registro.fechaSolicitudFormato = this.convertirFechaATimezone(json.fechaSolicitud, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            }

            if (json.fechaAvaluo) {
                registro.fechaAvaluoFormato = this.convertirFechaATimezone(json.fechaAvaluo, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            }

            if (json.fechaAprobacion) {
                registro.fechaAprobacionFormato = this.convertirFechaATimezone(json.fechaAprobacion, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            }

            if (json.fechaConfirmado) {
                registro.fechaConfirmadoFormato = this.convertirFechaATimezone(json.fechaConfirmado, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            }

            if (json.fechaRechazado) {
                registro.fechaRechazadoFormato = this.convertirFechaATimezone(json.fechaRechazado, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            }

            if (json.fechaRecibido) {
                registro.fechaRecibidoFormato = this.convertirFechaATimezone(json.fechaRecibido, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            }

            if (json.fechaAprobadoNoEntregado) {
                registro.fechaAprobadoNoEntregadoFormato = this.convertirFechaATimezone(json.fechaAprobadoNoEntregado, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            }

            if (json.inspeccionesVisuales) {
                registro.listadoInspeccionesVisualesJson = JSON.parse(json.inspeccionesVisuales);
            }
            if (json.inspeccionGrafica) {
                registro.listadoInspeccionesGraficasJson = JSON.parse(json.inspeccionGrafica);
            }
            if (json.listadoDescuentos) {
                registro.DescuentosJson = JSON.parse(json.listadoDescuentos);
            }
            if (json.otrasInspecciones) {
                registro.otrasInspeccionesJson = JSON.parse(json.otrasInspecciones);
            }
            if (json.MarcaVehiculo) {
                registro.marcaVehiculo = this.marcaVehiculoDeJSON(json.MarcaVehiculo);
            }
            if (json.LineaVehiculo) {
                registro.lineaVehiculo = this.lineaVehiculoDeJSON(json.LineaVehiculo);
            }
            if (json.Cliente) {
                registro.cliente = this.clienteDeJSON(json.Cliente);
            }
            if (json.Empresa) {
                registro.empresa = this.empresaDeJSON(json.Empresa);
            }
            if (json.UsuarioVendedor) {
                registro.usuarioVendedor = this.usuarioDeJSON(json.UsuarioVendedor);
                registro.nombreVendedor = registro.usuarioVendedor.nombreCompleto;
            }
            if (json.UsuarioAvaluador) {
                registro.usuarioAvaluador = this.usuarioDeJSON(json.UsuarioAvaluador);
                registro.nombreAvaluador = registro.usuarioAvaluador.nombreCompleto;
            }
            if (json.UsuarioGerencia) {
                registro.usuarioGerencia = this.usuarioDeJSON(json.UsuarioGerencia);
                registro.nombreGerencia = registro.usuarioGerencia.nombreCompleto;
            }
            if (json.carroceria) {
                registro.carroceriaObj = JSON.parse(json.carroceria);
                if (registro.carroceriaObj) registro.nombreCarroceria = registro.carroceriaObj.nombre;
            }
            if (json.estado) {
                var index = this.indexOf(this.apiURLs.estadoAvaluo, 'id', json.estado);
                if (index != -1) registro.estadoCapitalize = this.apiURLs.estadoAvaluo[index].nombre;
            }
            if (json.tipo) {
                var index = this.indexOf(this.apiURLs.tipoAvaluo, 'id', json.tipo);
                if (index != -1) registro.tipoCapitalize = this.apiURLs.tipoAvaluo[index].nombre;
            }

            if (json.transmision) {
                registro.transmisionNombre = this.apiURLs.obtenerNombre(this.apiURLs.transmision, json.transmision);
            }

            //depreciación
            if (registro.devaluacionEstandar && registro.precioLista && registro.iva) {
                registro.depreciacion = registro.precioLista - registro.iva - registro.devaluacionEstandar
            }

            return registro;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public cambioEstadoAvaluoDeJSON = (json: any): CambioEstadoAvaluo => {
        if (!json) return null;
        var registro = new CambioEstadoAvaluo(
            json.id,
            json.fecha,
            json.estadoAnterior,
            json.estadoPosterior,
            json.descripcion,
            json.AvaluoId,
            json.UsuarioId,
        );

        if (json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.Avaluo) {
            registro.avaluo = this.avaluoDeJSON(json.Avaluo);
        }

        if (json.fecha) {
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if (json.estadoAnterior) {
            let index = this.indexOf(this.apiURLs.estadoAvaluo, 'id', json.estadoAnterior);
            if (index != -1) registro.estadoAnteriorFormat = this.apiURLs.estadoAvaluo[index].nombre;
        }

        if (json.estadoPosterior) {
            let index = this.indexOf(this.apiURLs.estadoAvaluo, 'id', json.estadoPosterior);
            if (index != -1) registro.estadoPosteriorFormat = this.apiURLs.estadoAvaluo[index].nombre;
        }

        return registro;
    }

    public unidadDeJSON = (json: any): Unidad => {
        if (!json) return null;
        var registro = new Unidad(
            json.id,
            json.placa,
            json.chasis,
            json.color,
            json.kilometraje,
            json.estado,
            json.eliminado,
            json.modelo,
            json.costoHora,
            json.costoDia,
            json.costoDia7a25,
            json.costoDia25,
            json.costoSemana,
            json.costoPorKilometro,
            json.numero,
            json.fechaAlta,
            json.observaciones,
            json.tipoVehiculo,
            json.motor,
            json.fechaEstimadaReparacion,
            json.propiedad,
            json.ubicacion,
            json.numeroInt,
            json.umbralKilometraje,
            json.rebajaDeducible,
            json.montoDeducibleRebajado,
            json.montoParticipacion,
            json.tipoCombustible,
            json.galonaje,
            json.kilometrajePorDia,
            json.grupo,
            json.tipoTransmision,
            json.nivelGasolina,
            json.danios,
            json.ubicacionActual,

            json.numeroPuertas,
            json.cilindros,
            json.centimetrosCubicos,
            json.tonelaje,

            json.anchoUnidad,
            json.largoUnidad,
            json.altoUnidad,
            json.anchoFurgon,
            json.largoFurgon,
            json.altoFurgon,
            json.fechaAdquisicion,
            json.precioAdquisicionSinIva,
            json.mesesDepreciar,

            // Relaciones
            json.MarcaId,
            json.LineaId,
            json.UsuarioId,
            json.ServicioActualId,
            json.EmpresaId,
        );

        if(registro.tipoVehiculo) {
            let tipo = this.apiURLs.tiposVehiculo.find(el => el.id == registro.tipoVehiculo);
            if(tipo) registro.nombreTipoVehiculo = tipo.nombre;
        }

        if (json.MarcaAhorrent) {
            registro.marca = this.marcaAhorrentDeJSON(json.MarcaAhorrent);
        }
        if (json.LineaAhorrent) {
            registro.linea = this.lineaAhorrentDeJSON(json.LineaAhorrent);
        }
        if (json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
        }
        if (json.ContratoAhorrents) {
            registro.ContratoAhorrents = json.ContratoAhorrents.map(element => this.contratoAhorrentDeJSON(element));
            if (registro.estado == 'rentado') registro.ContratoAhorrentActual = registro.ContratoAhorrents[0];
        }
        /*if (json.ServicioUnidad) {
            registro.servicioActual = this.servicioUnidadDeJSON(json.ServicioUnidad);
        }*/
        if (json.Empresa) {
            registro.empresa = this.empresaDeJSON(json.Empresa);
        }

        if (json.CambiosEstadosUnidades && Array.isArray(json.CambiosEstadosUnidades)) {
            registro.cambiosEstadosUnidades = [];
            for (let cambio of json.CambiosEstadosUnidades) {
                registro.cambiosEstadosUnidades.push(this.cambioEstadoUnidadDeJSON(cambio));
            }
        }

        // Nombre completo
        let nombreCompleto = '';
        if (registro.numero) nombreCompleto += registro.numero;
        if (registro.placa) nombreCompleto += ' - ' + registro.placa;
        if (registro.marca) nombreCompleto += ' - ' + registro.marca.nombre;
        if (registro.linea) nombreCompleto += ' - ' + registro.linea.nombre;
        if (registro.chasis) nombreCompleto += ' - ' + registro.chasis;
        if (registro.estado) nombreCompleto += ' - ' + registro.estado;
        registro.nombreUnidadCompleto = nombreCompleto;

        //Fechas
        if (json.fechaAlta) {
            registro.fechaAltaFormato = this.convertirFecha(json.fechaAlta, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaAltaDate = this.convertirFecha(json.fechaAlta, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaEstimadaReparacion) {
            registro.fechaEstimadaReparacionFormato = this.convertirFecha(json.fechaEstimadaReparacion, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaEstimadaReparacionDate = this.convertirFecha(json.fechaEstimadaReparacion, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaAdquisicion) {
            registro.fechaAdquisicionFormato = this.convertirFecha(json.fechaAdquisicion, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaAdquisicionDate = this.convertirFecha(json.fechaAdquisicion, 'YYYY-MM-DD', 'date');
        }

        if (json.danios) {
            registro.listadoDaniosJson = JSON.parse(json.danios);
        }

        if(json.ImagenUnidads && json.ImagenUnidads) {
            let primeraImagen = this.imagenUnidadDeJSON(json.ImagenUnidads[0]);
            registro.imagenDefault = primeraImagen;
        }

        registro.ContratoIdEnCurso = json.ContratoIdEnCurso;

        return registro;
    }

    public clienteAhorrentDeJSON = (json: any): ClienteAhorrent => {
        if (!json) return null;
        var registro = new ClienteAhorrent(
            json.id,
            json.nombres,
            json.apellidos,
            json.nombreCompleto,
            json.email,
            json.telefono,
            json.telefono2,
            json.activo,
            json.eliminado,
            json.imagenPerfil,
            json.dpi,
            json.nit,
            json.direccion,
            json.nombreEmpresa,
            json.asesorCliente,
            json.clienteFrecuente,
            json.tipoCliente,
            json.categoria,
            json.fechaNacimiento,
            json.nacionalidad,
            //Relaciones
            json.EmpresaId,
        );

        registro.nombreCompleto = (json.nombres ? json.nombres : '') + ' ' + (json.apellidos ? json.apellidos : '');
        registro.nombreCompletoNit = (json.nombres ? json.nombres : '') + ' ' + (json.apellidos ? json.apellidos : '') + ' (' + (json.nit ? json.nit : '') + ')';
        registro.nombreCompletoNitDireccion = (json.nombres ? json.nombres : '') + ' ' + (json.apellidos ? json.apellidos : '') + ' (' + (json.nit ? json.nit : '') + ') - (' + (json.direccion ? json.direccion : '') + ')';

        if (json.Empresa) {
            registro.empresa = this.empresaDeJSON(json.Empresa);
        }

        if (json.DocumentosClientes && Array.isArray(json.DocumentosClientes)) {
            for (let documento of json.DocumentosClientes) {
                registro.documentosClientes.push(this.documentoClienteDeJSON(documento));
            }
        }

        if (json.SeguimientosClientes && Array.isArray(json.SeguimientosClientes)) {
            for (let seguimiento of json.SeguimientosClientes) {
                registro.seguimientosClientes.push(this.seguimientoClienteDeJSON(seguimiento));
            }
        }

        if (json.fechaNacimiento) {
            registro.fechaNacimientoFormato = this.convertirFechaATimezone(json.fechaNacimiento, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
            registro.fechaNacimientoDate = this.convertirFecha(json.fechaNacimiento, 'YYYY-MM-DD', 'date');
        }

        if(json.ContratoAhorrents) {
            registro.ContratosAhorrent = json.ContratoAhorrents.map(element => this.contratoAhorrentDeJSON(element));
        }

        return registro;
    }

    public seguimientoClienteDeJSON = (json: any): SeguimientoCliente => {
        if (!json) return null;
        var registro = new SeguimientoCliente(
            json.id,
            json.tipo,
            json.fecha,
            json.observaciones,
            //Relaciones
            json.ClienteId,
            json.UsuarioId,
            json.MarcaVehiculoId,
            json.LineaVehiculoId,
        );


        if (json.ClienteAhorrent) {
            registro.cliente = this.clienteAhorrentDeJSON(json.ClienteAhorrent);
        }

        if (json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.MarcaVehiculo) {
            const marca = this.marcaVehiculoDeJSON(json.MarcaVehiculo);
            registro.marcaVehiculo = marca;
            registro.nombreMarca = marca.nombre
        }

        if (json.LineaVehiculo) {
            const linea = this.lineaVehiculoDeJSON(json.LineaVehiculo);
            registro.lineaVehiculo = linea;
            registro.nombreLinea = linea.nombre;
        }

        if (json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if (json.tipo) {
            registro.tipoFormato = this.apiURLs.obtenerNombre(this.apiURLs.opcionesSeguimiento, json.tipo);
        }

        return registro;
    }

    public configuracionAhorrentDeJSON = (json: any): ConfiguracionAhorrent => {
        if (!json) return null;
        var registro = new ConfiguracionAhorrent(
            json.id,
            json.talleres,
            json.tiposDocumentos,
            json.rubrosGastos,
            json.modosPago,
            json.categoriasCliente,
            json.serviciosKilometraje,
            json.lavadores,
            json.mecanicos,
            json.cambioDolares,
            json.agencias,
            json.precioDiesel,
            json.precioGasolina,
            json.tarifaNormalProteccionChoques,
            json.tarifaReduccionDeducibleProteccionChoques,
            json.agentesYPilotos,
            json.porcentajesEficiencia,
            json.porcentajesAniosEficiencia,
            json.marcasTarjetas,
            json.devolucionVehiculo,
            json.correosAvisosContratos,
            json.grupos,
            json.correosPendientesUnidad,
            json.correosAgentesPendientesListoUnidad,
            json.correosAlertasLavado,
            json.correosVehiculosVenta,
            json.aniosVehiculoEnVenta,
            json.tiposTransmision,
            json.restriccionesContratos,
            json.origenRenta,
            json.porcentajesDescuento,
            json.usuariosAgencia,
            json.usuariosDashboards,
            json.referidos,
            json.tipoDanios,
            json.multas,
            json.monedasMultas,
            json.paisesMultas,
            json.establecimientos,

            json.seguroCuotaConductorMenor25,
            json.seguroProteccionLlantasParabrisas,
            json.servicioCartaPoderFrontera,
            json.servicioNocheLibre,
            json.servicioSillaBebe,
            json.servicioAcoplarRemolque,
            json.servicioCamperPickup,
            json.servicioPiloto,
            json.pap,

            json.encuestaIndividual,
            json.encuestaCorporativa,
            json.textosEncuestaFinalizada,

            json.horasInicioVerdeOperaciones,
            json.horasFinVerdeOperaciones,
            json.horasInicioAmarilloOperaciones,
            json.horasFinAmarilloOperaciones,
            json.horasInicioRojoOperaciones,
            json.horasFinRojoOperaciones,

            json.horasInicioVerdePendientesTaller,
            json.horasFinVerdePendientesTaller,
            json.horasInicioAmarilloPendientesTaller,
            json.horasFinAmarilloPendientesTaller,
            json.horasInicioRojoPendientesTaller,
            json.horasFinRojoPendientesTaller,
        );

        return registro;
    }



    public configuracionLandingDeJSON = (json: any): ConfiguracionLanding => {
        if (!json) return null;
        var registro = new ConfiguracionLanding(
            json.id,
            json.disclaimerPrecios,
        );

        return registro;
    }

    public documentoClienteDeJSON = (json: any): DocumentoCliente => {
        if (!json) return null;
        var registro = new DocumentoCliente(
            json.id,
            json.tipo,
            json.descripcion,
            json.url,
            json.fechaExpiracion,
            //Relaciones
            json.ClienteId,
        );

        if (json.ClienteAhorrent) {
            registro.cliente = this.clienteAhorrentDeJSON(json.ClienteAhorrent);
        }

        if (json.fechaExpiracion) {
            registro.fechaExpiracion = this.convertirFecha(json.fechaExpiracion, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaExpiracionFormato = this.convertirFecha(json.fechaExpiracion, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaExpiracionDate = this.convertirFecha(json.fechaExpiracion, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    public cambioEstadoContratoAhorrentDeJSON = (json: any): CambioEstadoContratoAhorrent => {
        if (!json) return null;
        var registro = new CambioEstadoContratoAhorrent(
            json.id,
            json.fecha,
            json.estadoAnterior,
            json.estadoPosterior,
            json.observaciones,
            //Relaciones
            json.UsuarioId,
            json.ContratoAhorrentId,
        );

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
        }
        if (json.ContratoAhorrent) {
            registro.ContratoAhorrent = this.contratoAhorrentDeJSON(json.ContratoAhorrent);
        }

        if (json.fecha) {
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    public cambioFechaContratoAhorrentDeJSON = (json: any): CambioFechaContratoAhorrent => {
        if (!json) return null;
        var registro = new CambioFechaContratoAhorrent(
            json.id,
            json.fechaAnterior,
            json.horaAnterior,
            json.fechaNueva,
            json.horaNueva,
            json.clienteAviso,
            json.observaciones,
            //Relaciones
            json.UsuarioId,
            json.ContratoAhorrentId,
        );

        if(registro.clienteAviso) registro.clienteAvisoFormato = 'Sí';
        else registro.clienteAvisoFormato = 'No';

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
            registro.nombreUsuario = registro.Usuario.nombreCompleto;
        }
        if (json.ContratoAhorrent) {
            registro.ContratoAhorrent = this.contratoAhorrentDeJSON(json.ContratoAhorrent);
        }

        if (json.fechaAnterior) {
            registro.fechaAnteriorFormato = this.convertirFecha(json.fechaAnterior, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaAnteriorDate = this.convertirFecha(json.fechaAnterior, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaNueva) {
            registro.fechaNuevaFormato = this.convertirFecha(json.fechaNueva, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaNuevaDate = this.convertirFecha(json.fechaNueva, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    public cambioEstadoUnidadDeJSON = (json: any): CambioEstadoUnidad => {
        if (!json) return null;
        var registro = new CambioEstadoUnidad(
            json.id,
            json.fecha,
            json.estadoAnterior,
            json.estadoPosterior,
            json.observaciones,
            json.kilometrajeActual,
            json.fechaInicial,
            json.horaInicial,
            //Relaciones
            json.UsuarioId,
            json.UnidadId,
            json.ServicioUnidadId,
        );

        if (json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
        }
        if (json.Unidad) {
            registro.unidad = this.unidadDeJSON(json.Unidad);
        }

        if (json.fecha) {
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaInicial) {
            registro.fechaInicialFormato = this.convertirFechaATimezone(json.fechaInicial, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaInicialDate = this.convertirFecha(json.fechaInicial, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    public servicioUnidadDeJSON = (json: any): ServicioUnidad => {
        if (!json) return null;
        var registro = new ServicioUnidad(
            json.id,
            json.fecha,
            json.fechaFin,
            json.tipo,
            json.taller,
            json.descripcion,
            json.personaEncargada,
            json.kilometrajeRecibido,
            json.finalizado,
            json.totalGastado,
            json.tipoProveedor,
            json.horaInicio,
            json.horaFin,
            //Relaciones
            json.UnidadId,
            json.UsuarioId,
            json.EmpresaId,
        );

        if (json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
        }
        if (json.Unidad) {
            registro.unidad = this.unidadDeJSON(json.Unidad);
        }

        if (json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');

            let fechaRegistro = new Date(json.fecha);
            let fechaActual = new Date();
            let diferenciaFechas = Number(fechaActual) - Number(fechaRegistro)
            registro.diasEnTaller = Math.trunc(diferenciaFechas / (1000 * 60 * 60 * 24));
        }
        if (json.fechaFin) {
            registro.fechaFin = this.convertirFecha(json.fechaFin, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFinFormato = this.convertirFecha(json.fechaFin, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaFinDate = this.convertirFecha(json.fechaFin, 'YYYY-MM-DD', 'date');
        }

        // Nombre completo
        let nombreCompleto = '';
        if (registro.taller) nombreCompleto += registro.taller;
        if (registro.personaEncargada) nombreCompleto += ' - ' + registro.personaEncargada;
        if (registro.tipo) nombreCompleto += ' - ' + registro.tipo;
        registro.nombreServicioPendiente = nombreCompleto;

        return registro;
    }

    public gastoServicioDeJSON = (json: any): GastoServicio => {
        if (!json) return null;
        var registro = new GastoServicio(
            json.id,
            json.rubro,
            json.descripcion,
            json.total,
            //Relaciones
            json.ServicioUnidadId,
            json.Usuario,
        );

        if (json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
        }
        if (json.ServicioUnidad) {
            registro.servicioUnidad = this.servicioUnidadDeJSON(json.ServicioUnidad);
        }

        return registro;
    }

    public campaniaPreventivaDeJSON = (json: any): CampaniaPreventiva => {
        if (!json) return null;
        var registro = new CampaniaPreventiva(
            json.id,
            json.nombre,
            json.codigo,
            json.fechaInicio,
            json.fechaFin,
            json.imagen,
            json.textoPromocional,
            json.descripcion,
        );

        if (json.fechaInicio) {
            registro.fechaInicio = this.convertirFecha(json.fechaInicio, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormatoInicio = this.convertirFecha(json.fechaInicio, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaDateInicio = this.convertirFecha(json.fechaInicio, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaFin) {
            registro.fechaFin = this.convertirFecha(json.fechaFin, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormatoFin = this.convertirFecha(json.fechaFin, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaDateFin = this.convertirFecha(json.fechaFin, 'YYYY-MM-DD', 'date');
        }

        if (json.VehiculosCampanias && Array.isArray(json.VehiculosCampanias)) {
            registro.VehiculosCampanias = [];
            for (let vehiculo of json.VehiculosCampanias) {
                registro.VehiculosCampanias.push(this.vehiculoCampaniaDeJSON(vehiculo));
            }
        }
        /*
                if (json.ReservacionesCampanias && Array.isArray(json.ReservacionesCampanias)) {
                    registro.ReservacionesCampanias = [];
                    for (let reservacionCampania of json.ReservacionesCampanias) {
                        registro.ReservacionesCampanias.push(this.reservacionCampaniaDeJSON(reservacionCampania));
                    }
                }*/

        return registro;
    }

    public vehiculoCampaniaDeJSON = (json: any): VehiculoCampania => {
        if (!json) return null;
        var registro = new VehiculoCampania(
            json.id,
            json.chasis,
            json.tipo,
            json.marca,
            json.modelo,
            json.numeroMotor,
            json.color,
            json.placa,
            json.vehiculoCliente,
            json.email,
            json.telefono,
            json.aplicado,
            //Relaciones
            json.CampaniaPreventivaId,
        );

        if (json.CampaniaPreventiva) registro.CampaniaPreventiva = this.campaniaPreventivaDeJSON(json.CampaniaPreventiva);

        return registro;
    }

    public reservacionCampaniaDeJSON = (json: any): ReservacionCampania => {
        if (!json) return null;
        var registro = new ReservacionCampania(
            json.id,
            json.nombre,
            json.email,
            json.fecha,
            json.fechaPropuesta,
            json.horaPropuesta,
            json.telefono,
            //Relaiones
            json.CampaniaPreventivaId,
            json.VehiculoCampaniaId,
        );

        if (json.fechaPropuesta) {
            registro.fechaPropuesta = this.convertirFecha(json.fechaPropuesta, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormatoPropuesta = this.convertirFecha(json.fechaPropuesta, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaDatePropuesta = this.convertirFecha(json.fechaPropuesta, 'YYYY-MM-DD', 'date');
        }
        if (json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if (json.CampaniaPreventiva) registro.CampaniaPreventiva = this.campaniaPreventivaDeJSON(json.CampaniaPreventiva);

        if (json.VehiculoCampania) registro.VehiculoCampania = this.vehiculoCampaniaDeJSON(json.VehiculoCampania);

        return registro;
    }

    public contratoAhorrentDeJSON = (json: any): ContratoAhorrent => {
        if (!json) return null;
        var registro = new ContratoAhorrent(
            json.id,
            json.estado,
            json.correlativo,
            json.correlativoReserva,
            json.fechaInicio,
            json.fechaFin,
            json.eliminado,
            json.observaciones,
            json.datosContrato,
            json.inspeccionSalida,
            json.inspeccionEntrada,
            json.esReserva,
            json.lavado,
            json.lavador,
            json.subtotalFinal,
            json.numeroImagenes ? json.numeroImagenes : 0,
            json.tiposVehiculo,
            json.origenRenta,
            json.nombreReferido,
            json.sucursal,
            json.codigoDescuento,
            json.montoPorcentajeDescuento,
            json.UsuarioDescuentoId,

            // Relaciones
            json.ClienteId,
            json.EmpresaId,
            json.UsuarioId,
            json.UnidadId,
        );

        if (registro.lavador) {
            registro.lavadorJSON = JSON.parse(registro.lavador);
        }

        if (registro.datosContrato) {
            registro.contrato = JSON.parse(json.datosContrato);
            if (registro.contrato.fechaEmision) {
                registro.contrato.fechaEmisionFormato = this.convertirFechaATimezone(registro.contrato.fechaEmision, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
                registro.contrato.fechaEmisionDate = this.convertirFecha(registro.contrato.fechaEmision, 'YYYY-MM-DD', 'date');
            }
            if (registro.contrato.fechaExpiracion) {
                registro.contrato.fechaExpiracionFormato = this.convertirFechaATimezone(registro.contrato.fechaExpiracion, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
                registro.contrato.fechaExpiracionDate = this.convertirFecha(registro.contrato.fechaExpiracion, 'YYYY-MM-DD', 'date');
            }
            if (registro.contrato.fechaNacimiento) {
                registro.contrato.fechaNacimientoFormato = this.convertirFechaATimezone(registro.contrato.fechaNacimiento, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
                registro.contrato.fechaNacimientoDate = this.convertirFecha(registro.contrato.fechaNacimiento, 'YYYY-MM-DD', 'date');
            }
            if (registro.contrato.fechaVencimientoTarjetaCredito) {
                registro.contrato.fechaVencimientoTarjetaCreditoFormato = this.convertirFechaATimezone(registro.contrato.fechaVencimientoTarjetaCredito, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
                registro.contrato.fechaVencimientoTarjetaCreditoDate = this.convertirFecha(registro.contrato.fechaVencimientoTarjetaCredito, 'YYYY-MM-DD', 'date');
            }
            if (registro.contrato.fechaDevolucionVehiculo) {
                registro.contrato.fechaDevolucionVehiculoFormato = this.convertirFechaATimezone(registro.contrato.fechaDevolucionVehiculo, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
                registro.contrato.fechaDevolucionVehiculoDate = this.convertirFecha(registro.contrato.fechaDevolucionVehiculo, 'YYYY-MM-DD', 'date');
            }
            if (registro.contrato.fechaSalida) {
                registro.contrato.fechaSalidaFormato = this.convertirFechaATimezone(registro.contrato.fechaSalida, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
                registro.contrato.fechaSalidaDate = this.convertirFecha(registro.contrato.fechaSalida, 'YYYY-MM-DD', 'date');
            }
            if (registro.contrato.fechaEntrada) {
                registro.contrato.fechaEntradaFormato = this.convertirFechaATimezone(registro.contrato.fechaEntrada, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
                registro.contrato.fechaEntradaDate = this.convertirFecha(registro.contrato.fechaEntrada, 'YYYY-MM-DD', 'date');
            }
            if (registro.contrato.fechaExpiracionLicencia) {
                registro.contrato.fechaExpiracionLicenciaFormato = this.convertirFechaATimezone(registro.contrato.fechaExpiracionLicencia, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
                registro.contrato.fechaExpiracionLicenciaDate = this.convertirFecha(registro.contrato.fechaExpiracionLicencia, 'YYYY-MM-DD', 'date');
            }
            if (registro.contrato.fechaExpiracionLicenciaPilotoAdicional) {
                registro.contrato.fechaExpiracionLicenciaPilotoAdicionalFormato = this.convertirFechaATimezone(registro.contrato.fechaExpiracionLicenciaPilotoAdicional, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
                registro.contrato.fechaExpiracionLicenciaPilotoAdicionalDate = this.convertirFecha(registro.contrato.fechaExpiracionLicenciaPilotoAdicional, 'YYYY-MM-DD', 'date');
            }
            if (registro.contrato.fechaEntregaVehiculo) {
                registro.contrato.fechaEntregaVehiculoFormato = this.convertirFechaATimezone(registro.contrato.fechaEntregaVehiculo, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
                registro.contrato.fechaEntregaVehiculoDate = this.convertirFecha(registro.contrato.fechaEntregaVehiculo, 'YYYY-MM-DD', 'date');
            }
            if (registro.contrato.fechaArriboEntrada) {
                registro.contrato.fechaArriboEntradaFormato = this.convertirFechaATimezone(registro.contrato.fechaArriboEntrada, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
                registro.contrato.fechaArriboEntradaDate = this.convertirFecha(registro.contrato.fechaArriboEntrada, 'YYYY-MM-DD', 'date');
            }
            if (registro.contrato.fechaPartidaSalida) {
                registro.contrato.fechaPartidaSalidaFormato = this.convertirFechaATimezone(registro.contrato.fechaPartidaSalida, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
                registro.contrato.fechaPartidaSalidaDate = this.convertirFecha(registro.contrato.fechaPartidaSalida, 'YYYY-MM-DD', 'date');
            }
        }
        else {
            registro.contrato = new ContenidoFormularioAhorrent();
        }

        if (json.ClienteAhorrent) {
            registro.cliente = this.clienteAhorrentDeJSON(json.ClienteAhorrent);
            registro.nombreCliente = registro.cliente.nombreCompletoNit;
        }

        if (json.estado) {
            let index = this.indexOf(this.apiURLs.estadosContratosAhorrent, 'id', json.estado);
            if (index != -1) registro.nombreEstado = this.apiURLs.estadosContratosAhorrent[index].nombre;
        }

        if (json.Empresa) {
            registro.empresa = this.empresaDeJSON(json.Empresa);
        }

        if (json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.Unidad) {
            registro.unidad = this.unidadDeJSON(json.Unidad);
            registro.nombreUnidad = registro.unidad.nombreUnidadCompleto;
        }

        if (json.fechaInicio) {
            registro.fechaInicio = this.convertirFecha(json.fechaInicio, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaInicioFormato = this.convertirFecha(json.fechaInicio, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaInicioDate = this.convertirFecha(json.fechaInicio, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaFin) {
            registro.fechaFin = this.convertirFecha(json.fechaFin, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFinFormato = this.convertirFecha(json.fechaFin, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaFinDate = this.convertirFecha(json.fechaFin, 'YYYY-MM-DD', 'date');
        }

        // Inspecciones
        if (json.inspeccionEntrada) {
            registro.inspeccionEntradaJson = JSON.parse(json.inspeccionEntrada);
            registro.inspeccionEntradaJson.inspeccionesDetalladasJson = registro.inspeccionEntradaJson.inspeccionesDetalladasJson ? registro.inspeccionEntradaJson.inspeccionesDetalladasJson : new InspeccionesDetalladasAhorrent();
        }

        if (json.inspeccionSalida) {
            registro.inspeccionSalidaJson = JSON.parse(json.inspeccionSalida);
            registro.inspeccionSalidaJson.inspeccionesDetalladasJson = registro.inspeccionSalidaJson.inspeccionesDetalladasJson ? registro.inspeccionSalidaJson.inspeccionesDetalladasJson : new InspeccionesDetalladasAhorrent();
        }

        if(json.MultaAhorrents) {
            registro.multas = [];
            for(let multa of json.MultaAhorrents) {
                registro.multas.push(this.multaAhorrentDeJSON(multa));
            }
        }

        return registro;
    }

    public lavadoEspecialDeJSON = (json: any): LavadoEspecial => {
        if (!json) return null;
        var registro = new LavadoEspecial(
            json.id,
            json.fecha,
            json.fechaAceptado,
            json.aprobado,
            json.lavado,
            json.observaciones,
            json.lavador,
            json.sucursal,
            json.color,
            json.placa,
            json.tipoVehiculo,
            json.EmpresaId,
            json.UsuarioId,
            json.UnidadId,
            json.MarcaId,
            json.LineaId,
        );

        if (registro.lavador) {
            registro.lavadorJSON = JSON.parse(registro.lavador);
        }

        if (json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
        }

        if(json.Marca) {
            registro.Marca = this.marcaVehiculoDeJSON(json.Marca);
        }

        if(json.Linea) {
            registro.Linea = this.lineaVehiculoDeJSON(json.Linea);
        }

        if (json.Unidad) {
            registro.unidad = this.unidadDeJSON(json.Unidad);
            registro.nombreUnidad = registro.unidad.nombreUnidadCompleto;

            if(registro.unidad.marca) registro.Marca = registro.unidad.marca;
            if(registro.unidad.linea) registro.Linea = registro.unidad.linea;
            registro.color = registro.unidad.color;
            registro.placa = registro.unidad.placa;
            registro.tipoVehiculo = registro.unidad.tipoVehiculo;
        }

        if (json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaAceptado) {
            registro.fechaAceptado = this.convertirFecha(json.fechaAceptado, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaAceptadoFormato = this.convertirFecha(json.fechaAceptado, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaAceptadoDate = this.convertirFecha(json.fechaAceptado, 'YYYY-MM-DD', 'date');
        }
        
        return registro;
    }

    // * * * SOB * * * 
    public concesionarioDeJSON = (json: any): Concesionario => {
        if (!json) return null;
        var registro = new Concesionario(
            json.id,
            json.nombre,
            json.logo,
            json.descripcion,
            json.direccion,
            json.activo,
            json.eliminado,
            json.horaInicioAtencion,
            json.horaFinAtencion,
            json.diasAtencion,
            json.horariosAtencion,
            json.fechasFestivas,
            json.razonesNoAtencion,

            json.codigoZip,
            json.numConcesionario,
            json.razonSocial,
            json.servicioRapido,
            json.pais,
            json.uf,
            json.ciudad,
            json.latitud,
            json.longitud,
            json.ddd,
            json.email,
            json.sitio,
            json.servicioEmail,
            json.facebookURL,
            json.telefono,
            json.fechaFeriado,
            json.abreFeriados,
            json.barrio,
            json.region,
        );

        if (json.horariosAtencion) registro.horariosAtencionJSON = JSON.parse(json.horariosAtencion);
        if (json.fechasFestivas) registro.fechasFestivasJSON = JSON.parse(json.fechasFestivas);
        if (json.razonesNoAtencion) registro.razonesNoAtencionJSON = JSON.parse(json.razonesNoAtencion);

        if (json.diasAtencion) {
            registro.diasAtencionArray = json.diasAtencion.split(',');
        }

        if (json.fechaFeriado) {
            registro.fechaFeriadoFormato = this.convertirFechaATimezone(json.fechaFeriado, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
            registro.fechaFeriadoDate = this.convertirFecha(json.fechaFeriado, 'YYYY-MM-DD', 'date');
        }


        return registro;
    }

    public citaDeJSON = (json: any): Cita => {
        if (!json) return null;
        var registro = new Cita(
            json.id,
            json.nombres,
            json.apellidos,
            json.nombreCompleto,
            json.correo,
            json.telefono,
            json.lineaVehiculo,
            json.servicio,
            json.rangoServicio,
            json.fecha,
            json.horaInicio,
            json.horaFin,
            json.fechaConfirmacion,
            json.kilometraje,
            json.placa,
            json.observaciones,
            json.imagenes,
            json.confirmada,
            json.uuid,
            json.horaPropuesta,
            json.fechaPropuesta,
            json.nit,
            json.fechaInicioAtencion,
            json.fechaFinAtencion,
            json.citaNoAtendida,
            json.razonNoAtendida,
            json.observacionesNoAtendida,
            json.minutosAtencion,
            json.confirmadaConCliente,
            json.chasis,
            json.marca,
            json.descripcionLinea,
            json.anioModelo,
            json.colorExterno,
            json.colorInterno,
            json.servicios,
            json.origen,
            json.suborigen,
            json.preferenciaContacto,
            json.AgenteId,
            json.ConcesionarioId,
            json.CampaniaPreventivaId,
            json.VehiculoCampaniaId,
        );

        if (json.fecha) {
            registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY');
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaConfirmacion) {
            registro.fechaConfirmacionFormato = this.convertirFechaATimezone(json.fechaConfirmacion, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaConfirmacionDate = this.convertirFecha(json.fechaConfirmacion, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaPropuesta) {
            registro.fechaPropuestaFormato = this.convertirFechaATimezone(json.fechaPropuesta, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaPropuestaDate = this.convertirFecha(json.fechaPropuesta, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaInicioAtencion) {
            registro.fechaInicioAtencionFormato = this.convertirFechaATimezone(json.fechaInicioAtencion, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaInicioAtencionDate = this.convertirFechaATimezone(json.fechaInicioAtencion, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
        }

        if (json.fechaFinAtencion) {
            registro.fechaFinAtencionFormato = this.convertirFechaATimezone(json.fechaFinAtencion, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaFinAtencionDate = this.convertirFechaATimezone(json.fechaFinAtencion, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
        }

        if (json.createdAt) {
            registro.fechacreatedAtFormato = this.convertirFechaATimezone(json.createdAt, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechacreatedAtDate = this.convertirFecha(json.createdAt, 'YYYY-MM-DD', 'date');
        }

        if (registro.imagenes) {
            registro.imagenesArray = registro.imagenes.split(',');
        }

        if (registro.servicio) {
            let index = this.indexOf(this.apiURLs.tipoServicioCitas, 'id', registro.servicio);
            if (index != -1) registro.nombreTipoServicio = this.apiURLs.tipoServicioCitas[index].nombre;
        }

        if (registro.rangoServicio) {
            let index = this.indexOf(this.apiURLs.rangosServicioCitas, 'id', registro.rangoServicio);
            if (index != -1) registro.nombreRangoServicio = this.apiURLs.rangosServicioCitas[index].nombre;
        }

        let horarioCompleto = '';
        if (registro.horaInicio) horarioCompleto += registro.horaInicio;
        if (registro.horaInicio) horarioCompleto += ' a ' + registro.horaFin;
        registro.horarioCompleto = horarioCompleto;

        let horaAtencion = '';
        if (registro.fechaInicioAtencion) horaAtencion += this.convertirFecha(registro.fechaInicioAtencion, 'date', 'HH:mm');
        if (registro.fechaFinAtencion) horaAtencion += ' - ' + this.convertirFecha(registro.fechaFinAtencion, 'date', 'HH:mm');
        registro.horaCompletaAtencion = horaAtencion;

        if (json.Concesionario) {
            let concesionario = this.concesionarioDeJSON(json.Concesionario);
            registro.concesionario = concesionario;
            registro.nombreConcesionario = concesionario.nombre;
        }

        if (json.Agente) {
            let agente = this.usuarioDeJSON(json.Agente);
            registro.agente = agente;
            registro.nombreAgente = agente.nombreCompleto;
        }

        if (json.servicios) {
            registro.serviciosJSON = JSON.parse(json.servicios);
            registro.serviciosFormato = '';

            if (registro.serviciosJSON && registro.serviciosJSON.length > 0) registro.serviciosFormato += `${registro.serviciosJSON[0].Descricao}: ${registro.serviciosJSON[0].Observacao}`;
            for (let x = 1; x < registro.serviciosJSON.length; x++) {
                const servicio = registro.serviciosJSON[x];
                registro.serviciosFormato += ` / ${servicio.Descricao}: ${servicio.Observacao}`;
            }
        }

        if (json.origen) {
            registro.origenString = this.apiURLs.origenesCita.find(element => element.id == registro.origen).nombre
        }

        if (json.suborigen) {
            registro.suborigenString = this.apiURLs.suborigenesCita.find(element => element.id == registro.suborigen).nombre
        }

        return registro;
    }

    public horarioCitaDeJSON = (json: any): HorarioCita => {
        if (!json) return null;
        let registro = new HorarioCita(
            json.horaInicio,
            json.horaFin,
            json.AgenteId,
            json.ConcesionarioId,
        );

        if (json.Concesionario) {
            let concesionario = this.concesionarioDeJSON(json.Concesionario);
            registro.concesionario = concesionario;
        }

        if (json.Agente) {
            let agente = this.usuarioDeJSON(json.Agente);
            registro.agente = agente;
        }

        return registro;
    }

    public servicioDeJSON = (json: any): Servicio => {
        if (!json) return null;
        var registro = new Servicio(
            json.id,
            json.nombre,
            json.descripcion,
            json.eliminado,
            json.imagen,
        );

        return registro;
    }

    public repuestoDeJSON = (json: any): Repuesto => {
        if (!json) return null;
        var registro = new Repuesto(
            json.id,
            json.nombre,
            json.descripcion,
            json.eliminado,
            json.imagen,
        );

        return registro;
    }

    public versionDeJSON = (json: any): Version => {
        if (!json) return null;
        var registro = new Version(
            json.id,
            json.nombre,
            json.descripcion,
            json.eliminado,
            json.codigosVersionesSOB,
            //Relaciones
            json.LineaId,
            json.ConcesionarioId
        );

        if (json.LineaVehiculo) {
            const lineaVehiculo = this.lineaVehiculoDeJSON(json.LineaVehiculo);
            registro.linea = lineaVehiculo;
            registro.nombreLineaVehiculo = lineaVehiculo.nombre;
        }

        if (json.Concesionario) {
            const concesionario = this.concesionarioDeJSON(json.Concesionario);
            registro.concesionario = concesionario;
            registro.nombreConcesionario = concesionario.nombre;
        }

        if (json.Kilometrajes && Array.isArray(json.Kilometrajes)) {
            registro.Kilometrajes = [];
            for (let kilometraje of json.Kilometrajes) {
                registro.Kilometrajes.push(this.kilometrajeDeJSON(kilometraje));
            }
        }

        if (json.codigosVersionesSOB) {
            registro.codigosVersionesSOBArray = JSON.parse(json.codigosVersionesSOB);
        }

        return registro;
    }

    public kilometrajeDeJSON = (json: any): Kilometraje => {
        if (!json) return null;
        var registro = new Kilometraje(
            json.id,
            json.nombre,
            json.descripcion,
            json.costo,
            json.eliminado,
            //Relaciones
            json.VersionId
        );

        if (json.Version) {
            registro.Version = this.versionDeJSON(json.Version);
        }

        if (json.RepuestoVersions && Array.isArray(json.RepuestoVersions)) {
            registro.RepuestosVersiones = [];
            for (let repuestoVersion of json.RepuestoVersions) {
                registro.RepuestosVersiones.push(this.repuestoVersionDeJSON(repuestoVersion));
            }
        }

        if (json.ServicioVersions && Array.isArray(json.ServicioVersions)) {
            registro.ServiciosVersiones = [];
            for (let servicioVersion of json.ServicioVersions) {
                registro.ServiciosVersiones.push(this.servicioVersionDeJSON(servicioVersion));
            }
        }

        return registro;
    }

    public repuestoVersionDeJSON = (json: any): RepuestoVersion => {
        if (!json) return null;
        var registro = new RepuestoVersion(
            json.id,
            json.RepuestoId,
            json.VersionId,
            json.KilometrajeId,
        );

        if (json.Version) {
            registro.Version = this.versionDeJSON(json.Version);
        }

        if (json.Kilometraje) {
            registro.Kilometraje = this.kilometrajeDeJSON(json.Kilometraje);
        }

        if (json.Repuesto) {
            registro.Repuesto = this.repuestoDeJSON(json.Repuesto);
        }

        return registro;
    }

    public servicioVersionDeJSON = (json: any): ServicioVersion => {
        if (!json) return null;
        var registro = new ServicioVersion(
            json.id,
            json.ServicioId,
            json.VersionId,
            json.KilometrajeId,
        );

        if (json.Version) {
            registro.Version = this.versionDeJSON(json.Version);
        }

        if (json.Kilometraje) {
            registro.Kilometraje = this.kilometrajeDeJSON(json.Kilometraje);
        }

        if (json.Servicio) {
            registro.Servicio = this.servicioDeJSON(json.Servicio);
        }

        return registro;
    }

    public servicioPendienteUnidadDeJSON = (json: any): ServicioPendienteUnidad => {
        if (!json) return null;
        var registro = new ServicioPendienteUnidad(
            json.id,
            json.nombre,
            json.observaciones,
            json.pendiente,
            json.kilometrajeAgregado,
            //Relaciones
            json.UnidadId,
            json.ServicioUnidadId,
        );

        if (json.Unidad) {
            registro.Unidad = this.unidadDeJSON(json.Unidad);
        }
        if (json.ServicioUnidad) {
            registro.ServicioUnidad = this.servicioUnidadDeJSON(json.ServicioUnidad);
        }

        return registro;
    }

    public versionLineaSOBDeJSON = (json: any): VersionLineaSOB => {
        if (!json) return null;
        var registro = new VersionLineaSOB(
            json.id,
            json.anio,
            json.codigoLinea,
            json.nombreLinea,
            json.nombreVersion,
            json.tipoMotor,
            json.descontinuado,
            json.VersionId,
        );

        if (json.Version) {
            registro.Version = this.versionDeJSON(json.Version);
        }

        return registro;
    }

    // Bestune
    public configuracionBestuneDeJSON = (json: any): ConfiguracionBestune => {
        if (!json) return null;
        var registro = new ConfiguracionBestune(
            json.id,
            json.modelos,
            json.marcasVehiculos,
            //Relaciones
            json.EmpresaId,
        );

        if (json.Empresa) {
            registro.Empresa = this.empresaDeJSON(json.Empresa);
        }

        return registro;
    }

    public repuestoBestuneDeJSON = (json: any): RepuestoBestune => {
        if (!json) return null;
        var registro = new RepuestoBestune(
            json.id,
            json.numeroRepuesto,
            json.nombreIngles,
            json.nombreEspaniol,
            json.codigo,
            json.codigoRetorno,
            json.cantidadPiezas,
            json.codigoImagen,
            json.codigoEnsamblaje,
            json.codigoGrupo,
            json.modelo,
            
            json.EmpresaId,
        );

        if (json.Empresa) {
            registro.Empresa = this.empresaDeJSON(json.Empresa);
        }

        return registro;
    }

    public vehiculoSOBDeJSON = (json: any): VehiculoSOB => {
        if (!json) return null;
        var registro = new VehiculoSOB(
            json.id,
            json.chasis,
            json.marca,
            json.anio,
            json.motor,
            json.tipoMotor,
            json.codigoLinea,
            json.nombreLinea,
            json.codigoVersion,
            json.nombreVersion,
            json.codigoColor,
            json.placas,
            json.fechaVenta,
        );

        return registro;
    }

    public solicitudPruebaManejoDeJSON = (json: any): SolicitudPruebaManejo => {
        if (!json) return null;
        var registro = new SolicitudPruebaManejo(
            json.id,
            json.fecha,
            json.estado,
            json.horaSalida,
            json.horaIngreso,
            json.kmSalida,
            json.kmIngreso,
            json.autonomiaSalida,
            json.autonomiaIngreso,
            json.sede,
            json.tipo,
            json.asesor,
            json.nombreCliente,
            json.dpiCliente,
            json.diasPrestamo,
            json.inspeccionEntrada,
            json.combustibleSalida,
            json.combustibleIngreso,
            json.fechaDevolucion,
            json.horaEstimadaIngreso,
            json.citaProgramada,
            json.citaCumplida,
            //Relaciones
            json.UsuarioSolicitadorId,
            json.UsuarioAprobadorId,
            json.VehiculoDemoId,
            json.ClienteBoletaId,
            json.EmpresaId,
        );

        registro.objetoId = {
            id: registro.id,
            nombre: registro.id
        }

        if (registro.kmIngreso && registro.kmSalida) registro.kmUtilizado = registro.kmIngreso - registro.kmSalida;
        if (registro.autonomiaIngreso && registro.autonomiaSalida) registro.autonomiaUtilizada = registro.autonomiaIngreso - registro.autonomiaSalida;

        if (json.inspeccionEntrada) {
            registro.inspeccionEntradaJson = JSON.parse(json.inspeccionEntrada);
        }

        if (json.Empresa) {
            registro.Empresa = this.empresaDeJSON(json.Empresa);
        }

        if (json.UsuarioAprobador) {
            registro.UsuarioAprobador = this.usuarioDeJSON(json.UsuarioAprobador);
        }

        if (json.UsuarioSolicitador) {
            registro.UsuarioSolicitador = this.usuarioDeJSON(json.UsuarioSolicitador);
        }

        if (json.ClienteBoletum) {
            registro.ClienteBoleta = this.clienteBoletaDeJSON(json.ClienteBoletum);
        }

        if (json.ClienteBoleta) {
            registro.ClienteBoleta = this.clienteBoletaDeJSON(json.ClienteBoleta);
        }

        if (json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaDevolucion) {
            registro.fechaDevolucion = this.convertirFecha(json.fechaDevolucion, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaDevolucionFormato = this.convertirFecha(json.fechaDevolucion, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaDevolucionDate = this.convertirFecha(json.fechaDevolucion, 'YYYY-MM-DD', 'date');
        }

        /*if (json.tipo) {
            var index = this.indexOf(this.apiURLs.tiposSolicitudPruebaManejo, 'id', json.tipo);
            registro.tipoFormato = this.apiURLs.tiposSolicitudPruebaManejo[index].nombre;
        }*/

        if (json.estado) {
            var index = this.indexOf(this.apiURLs.estadosSolicitudPruebaManejo, 'id', json.estado);
            registro.estadoFormato = this.apiURLs.estadosSolicitudPruebaManejo[index].nombre;
        }

        if (json.VehiculoDemo) {
            registro.VehiculoDemo = this.vehiculoDemoDeJSON(json.VehiculoDemo);
        }

        return registro;
    }

    public configuracionDigitalizacionDeJSON = (json: any): ConfiguracionDigitalizacion => {
        if (!json) return null;
        var registro = new ConfiguracionDigitalizacion(
            json.id,
            json.sedes,
            json.asesores,
            json.marcas,
            json.modelos,
            json.tiposSolicitudes,
            json.coloresVehiculos,
            json.versionesVehiculos,
            json.correosNuevasSolicitudes,
            json.kmRutaLarga,
            //Relaciones
            json.EmpresaId,
        );



        return registro;
    }

    public configuracionPlanMantenimientoDeJSON = (json: any): ConfiguracionPlanMantenimiento => {
        if (!json) return null;
        var registro = new ConfiguracionPlanMantenimiento(
            json.id,
            json.correosRecordatorios,
            json.plantillaCorreoContratacion,
            json.asesoresContrataciones,
            //Relaciones
            json.EmpresaId,
        );



        return registro;
    }

    public configuracionLeadDeJSON = (json: any): ConfiguracionLead => {
        if (!json) return null;
        var registro = new ConfiguracionLead(
            json.id,
            json.canalesIngresos,
            json.canalesWeb,
            json.estatusCita,
            json.opcionesPago,
            json.institucionesFinancieras,
            json.motivosDesistimientos,
            json.tiposSeguimientos,
            json.unidades,
            //Relaciones
            json.EmpresaId,
        );
        return registro;
    }

    public prospectoLeadDeJSON = (json: any): ProspectoLead => {
        if (!json) return null;
        var registro = new ProspectoLead(
            json.id,
            json.canalIngreso,
            json.canalWeb,

            json.MarcaVehiculoRequeridoId,
            json.LineaVehiculoId,

            json.modeloRequerido,
            json.placaRequerida,

            json.MarcaVehiculoActualId,

            json.observaciones,

            json.fechaSeguimiento,
            json.fechaRegistro,

            json.UsuarioAsesorId,

            json.estado,

            json.unidad,

            json.estatusCita,
            json.opcionPago,
            json.institucionFinanciera,
            json.motivoDesistimiento,

            json.incluyeMarcaActual,

            json.ClienteId,
            json.EmpresaId,
            json.UsuarioCreadorId,
        );
        if (json.fechaSeguimiento) {
            registro.fechaSeguimientoFormato = this.convertirFechaATimezone(registro.fechaSeguimiento, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaSeguimientoDate = this.convertirFecha(json.fechaSeguimiento, 'YYYY-MM-DD', 'date');
        }
        if (json.fechaRegistro) {
            registro.fechaRegistroFormato = this.convertirFechaATimezone(json.fechaRegistro, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaRegistroDate = this.convertirFecha(json.fechaRegistro, 'YYYY-MM-DD', 'date');
        }

        if (json.MarcaRequerida) {
            registro.MarcaRequerida = this.marcaVehiculoDeJSON(json.MarcaRequerida);
        }
        if (json.LineaRequerida) {
            registro.LineaRequerida = this.lineaVehiculoDeJSON(json.LineaRequerida);
        }
        if (json.MarcaActual) {
            registro.MarcaActual = this.marcaVehiculoDeJSON(json.MarcaActual);
        }
        if (json.UsuarioAsesor) {
            registro.UsuarioAsesor = this.usuarioDeJSON(json.UsuarioAsesor);
        }
        if (json.UsuarioCreador) {
            registro.UsuarioCreador = this.usuarioDeJSON(json.UsuarioCreador);
        }
        if (json.Cliente) {
            registro.Cliente = this.clienteDeJSON(json.Cliente);
        }
        if (json.estado) {
            let index = this.indexOf(this.apiURLs.estadosProspecto, 'id', json.estado);
            if (index != -1) registro.estadoFormat = this.apiURLs.estadosProspecto[index].nombre;
        }
        return registro;
    }

    public cambioEstadoProspectoLeadDeJSON = (json: any): CambioEstadoProspectoLead => {
        if (!json) return null;
        var registro = new CambioEstadoProspectoLead(
            json.id,
            json.estadoAnterior,
            json.estadoPosterior,
            json.observaciones,
            json.fecha,

            json.UsuarioId,
            json.ProspectoLeadId,
        );

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.ProspectoLead) {
            registro.ProspectoLead = this.prospectoLeadDeJSON(json.ProspectoLead);
        }

        if (json.fecha) {
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if (json.estadoAnterior) {
            let index = this.indexOf(this.apiURLs.estadosProspecto, 'id', json.estadoAnterior);
            if (index != -1) registro.estadoAnteriorFormat = this.apiURLs.estadosProspecto[index].nombre;
        }

        if (json.estadoPosterior) {
            let index = this.indexOf(this.apiURLs.estadosProspecto, 'id', json.estadoPosterior);
            if (index != -1) registro.estadoPosteriorFormat = this.apiURLs.estadosProspecto[index].nombre;
        }

        return registro;
    }

    public seguimientoProspectoLeadDeJSON = (json: any): SeguimientoProspectoLead => {
        if (!json) return null;
        var registro = new SeguimientoProspectoLead(
            json.id,
            json.fechaCreacion,
            json.fechaAnterior,
            json.fechaNueva,
            json.tipoSeguimiento,
            json.observaciones,

            json.UsuarioId,
            json.ProspectoLeadId,
        );

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.ProspectoLead) {
            registro.ProspectoLead = this.prospectoLeadDeJSON(json.ProspectoLead);
        }

        if (json.fechaCreacion) {
            registro.fechaCreacionFormato = this.convertirFechaATimezone(json.fechaCreacion, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaCreacionDate = this.convertirFecha(json.fechaCreacion, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaAnterior) {
            registro.fechaAnteriorFormato = this.convertirFechaATimezone(json.fechaAnterior, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaAnteriorDate = this.convertirFecha(json.fechaAnterior, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaNueva) {
            registro.fechaNuevaFormato = this.convertirFechaATimezone(json.fechaNueva, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaNuevaDate = this.convertirFecha(json.fechaNueva, 'YYYY-MM-DD', 'date');
        }


        return registro;
    }

    public cambioEstadoSolicitudPruebaManejoDeJSON = (json: any): CambioEstadoSolicitud => {
        if (!json) return null;
        var registro = new CambioEstadoSolicitud(
            json.id,
            json.fecha,
            json.estadoAnterior,
            json.estadoPosterior,
            json.observaciones,
            json.kilometrajeActual,
            //Relaciones
            json.UsuarioId,
            json.SolicitudPruebaManejoId,
        );

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
        }
        if (json.SolicitudPruebaManejo) {
            registro.SolicitudPruebaManejo = this.solicitudPruebaManejoDeJSON(json.SolicitudPruebaManejo);
        }

        if (json.fecha) {
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    public DocumentoSolicitudDeJSON = (json: any): DocumentoSolicitud => {
        if (!json) return null;
        var registro = new DocumentoSolicitud(
            json.id,
            json.descripcion,
            json.url,
            //Relaciones
            json.SolicitudPruebaManejoId,
            json.EmpresaId,
        );

        if (json.SolicitudPruebaManejo) {
            registro.SolicitudPruebaManejo = this.solicitudPruebaManejoDeJSON(json.SolicitudPruebaManejo);
        }

        if (json.Empresa) {
            registro.Empresa = this.empresaDeJSON(json.Empresa);
        }

        return registro;
    }

    public vehiculoDemoDeJSON = (json: any): VehiculoDemo => {
        if (!json) return null;
        var registro = new VehiculoDemo(
            json.id,
            json.chasis,
            json.tipo,
            json.marca,
            json.modelo,
            json.numeroMotor,
            json.color,
            json.placa,
            json.inactivo,
            json.version,
            json.umbralKilometraje,
            //Relaciones
            json.EmpresaId,
        );

        registro.objetoPlaca = {
            id: registro.id,
            nombre: registro.placa
        }

        if (json.Empresa) {
            registro.Empresa = this.empresaDeJSON(json.Empresa);
        }
        if (json.placa) {
            let aux = [];
            if (json.modelo) aux.push(json.modelo);
            if (json.version) aux.push(json.version);
            if (json.placa) aux.push(json.placa);
            if (json.color) aux.push(json.color);
            registro.nombreVehiculo = aux.join('-');
        }

        return registro;
    }

    public clienteBoletaDeJSON = (json: any): ClienteBoleta => {
        if (!json) return null;
        var registro = new ClienteBoleta(
            json.id,
            json.nombres,
            json.apellidos,
            json.nombreCompleto,
            json.email,
            json.telefono,
            json.activo,
            json.eliminado,
            json.dpi,
            json.nit,
            json.noEsClienteContinental,

            //Relaciones
            json.EmpresaId,
        );

        registro.objetoNombreCompleto = {
            id: registro.id,
            nombre: registro.nombreCompleto
        }

        registro.objetoDpi = {
            id: registro.id,
            nombre: registro.dpi
        }

        if (json.Empresa) {
            registro.Empresa = this.empresaDeJSON(json.Empresa);
        }

        return registro;
    }

    public clientePlanMantenimientoDeJSON = (json: any): ClientePlanMantenimiento => {
        if (!json) return null;
        var registro = new ClientePlanMantenimiento(
            json.id,
            json.nombres,
            json.apellidos,
            json.nombreCompleto,
            json.email,
            json.telefono,
            json.activo,
            json.eliminado,
            json.dpi,
            json.nit,

            //Relaciones
            json.EmpresaId,
        );

        if (json.Empresa) {
            registro.Empresa = this.empresaDeJSON(json.Empresa);
        }

        return registro;
    }

    public vehiculoPlanMantenimientoDeJSON = (json: any): VehiculoPlanMantenimiento => {
        if (!json) return null;
        var registro = new VehiculoPlanMantenimiento(
            json.id,
            json.chasis,
            json.tipo,
            json.marca,
            json.modelo,
            json.numeroMotor,
            json.color,
            json.placa,
            json.kilometraje,
            json.linea,
            json.eliminado,
            //Relaciones
            json.EmpresaId,
        );

        if (json.Empresa) {
            registro.Empresa = this.empresaDeJSON(json.Empresa);
        }
        if (json.placa && json.marca) {
            registro.nombreVehiculo = `${json.placa}-${json.marca}-${json.color ? json.color : ''}`;
        }

        return registro;
    }

    public planDeMantenimientoDeJSON = (json: any): PlanDeMantenimiento => {
        if (!json) return null;
        var registro = new PlanDeMantenimiento(
            json.id,
            json.nombre,
            json.descripcion,
            json.fechaInicio,
            json.fechaFin,
            json.precio,
            json.activo,

            //Relaciones
            json.EmpresaId,
        );

        if (json.Empresa) {
            registro.Empresa = this.empresaDeJSON(json.Empresa);
        }

        if (json.fechaInicio) {
            registro.fechaInicioFormato = this.convertirFecha(json.fechaInicio, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaInicioDate = this.convertirFecha(json.fechaInicio, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaFin) {
            registro.fechaFinFormato = this.convertirFecha(json.fechaFin, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaFinDate = this.convertirFecha(json.fechaFin, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    public itemPlanDeMantenimientoDeJSON = (json: any): ItemPlanDeMantenimiento => {
        if (!json) return null;
        var registro = new ItemPlanDeMantenimiento(
            json.id,
            json.nombre,
            json.descripcion,
            json.cantidadUso,

            //Relaciones
            json.PlanDeMantenimientoId,
        );

        if (json.PlanDeMantenimiento) {
            registro.PlanDeMantenimiento = this.planDeMantenimientoDeJSON(json.PlanDeMantenimiento);
        }

        return registro;
    }

    public contratacionDeJSON = (json: any): Contratacion => {
        if (!json) return null;
        var registro = new Contratacion(
            json.id,
            json.pendienteCancelar,
            json.uuid,
            json.fechaContratacion,
            json.Asesor,
            json.totalPagosEnCajaConIVA,
            json.totalPagosEnCajaSinIVA,
            json.codigoClienteIntegra,
            json.kilometraje,
            //Relaciones
            json.ClientePlanMantenimientoId,
            json.VehiculoPlanMantenimientoId,
            json.PlanDeMantenimientoId,
        );

        if (json.ClientePlanMantenimiento) {
            registro.ClientePlanMantenimiento = this.clientePlanMantenimientoDeJSON(json.ClientePlanMantenimiento);
        }

        if (json.VehiculoPlanMantenimiento) {
            registro.VehiculoPlanMantenimiento = this.vehiculoPlanMantenimientoDeJSON(json.VehiculoPlanMantenimiento);
        }

        if (json.PlanDeMantenimiento) {
            registro.PlanDeMantenimiento = this.planDeMantenimientoDeJSON(json.PlanDeMantenimiento);
        }

        if (json.fechaContratacion) {
            registro.fechaContratacion = this.convertirFecha(json.fechaContratacion, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaContratacionFormato = this.convertirFecha(json.fechaContratacion, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaContratacionDate = this.convertirFecha(json.fechaContratacion, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    public ordenTrabajoPlanMantenimientoDeJSON = (json: any): OrdenTrabajoPlanMantenimiento => {
        if (!json) return null;
        var registro = new OrdenTrabajoPlanMantenimiento(
            json.id,
            json.titulo,
            json.fechaFacturacion,
            json.observaciones,
            json.cargoCliente,
            json.definicionCargoCliente,
            json.fechaSugeridaProximoServicio,
            json.valorConIVA,
            json.tipoServicio,
            json.costoRepuesto,
            json.ventaRepuesto,
            json.ventaManoObra,
            json.numeroFactura,
            json.fechaPago,
            json.pagosEnCajaConIVA,
            json.pagosEnCajaSinIVA,
            json.comentarios,
            //Relaciones
            json.ContratacionId,
            json.ItemPlanMantenimientoId,
        );

        if (json.Contratacion) {
            registro.Contratacion = this.contratacionDeJSON(json.Contratacion);
        }
        if (json.ItemPlanDeMantenimiento) {
            registro.ItemPlanMantenimiento = this.itemPlanDeMantenimientoDeJSON(json.ItemPlanDeMantenimiento);
        }


        if (json.fechaFacturacion) {
            registro.fechaFacturacion = this.convertirFecha(json.fechaFacturacion, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFacturacionFormato = this.convertirFecha(json.fechaFacturacion, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaFacturacionDate = this.convertirFecha(json.fechaFacturacion, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaPago) {
            registro.fechaPago = this.convertirFecha(json.fechaPago, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaPagoFormato = this.convertirFecha(json.fechaPago, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaPagoDate = this.convertirFecha(json.fechaPago, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaSugeridaProximoServicio) {
            registro.fechaSugeridaProximoServicio = this.convertirFecha(json.fechaSugeridaProximoServicio, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaSugeridaProximoServicioFormato = this.convertirFecha(json.fechaSugeridaProximoServicio, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaSugeridaProximoServicioDate = this.convertirFecha(json.fechaSugeridaProximoServicio, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaSugeridaProximoServicio) {
            registro.fechaSugeridaProximoServicioFormato = this.convertirFecha(json.fechaSugeridaProximoServicio, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaSugeridaProximoServicioDate = this.convertirFecha(json.fechaSugeridaProximoServicio, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    public documentoContratacionDeJSON = (json: any): DocumentoContratacion => {
        if (!json) return null;
        var registro = new DocumentoContratacion(
            json.id,
            json.descripcion,
            json.fechaSubida,
            json.url,
            //Relaciones
            json.UsuarioId,
            json.ContratacionId,
        );

        if (json.Contratacion) {
            registro.Contratacion = this.contratacionDeJSON(json.Contratacion);
        }

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
        }


        if (json.fechaSubida) {
            registro.fechaSubida = this.convertirFecha(json.fechaSubida, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaSubidaFormato = this.convertirFecha(json.fechaSubida, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaSubidaDate = this.convertirFecha(json.fechaSubida, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    //SolicitudTraspasos
    public solicitudTraspasoDeJSON = (json: any): SolicitudTraspaso => {
        if (!json) return null;
        var registro = new SolicitudTraspaso(
            json.id,
            json.numeroPlaca,
            json.nombreCliente,
            json.tipoRecepcionUsado,
            json.aplicaPagoDeuda,
            json.llevaCheque,
            json.clienteFactura,
            json.persona,
            json.fechaSolicitud,
            json.sucursalVendedor,
            json.sucursalTraspaso,
            json.eliminado,
            json.estado,
            json.avaluo,
            json.rechazadoAvaluo,
            json.dpiPasaporteVigente,
            json.rechazadoDpiPasaporteVigente,
            json.rtu,
            json.rechazadoRTU,
            json.impuestoCirculacionAnioCurso,
            json.rechazadoImpuestoCirculacionAnioCurso,
            json.omiso,
            json.rechazadoOmiso,
            json.solvenciaMulta,
            json.rechazadoSolvenciaMulta,
            json.tarjetaTitulo,
            json.rechazadoTarjetaTitulo,
            json.tarjetaCirculacion,
            json.rechazadoTarjetaCirculacion,
            json.representacionLegal,
            json.rechazadoRepresentacionLegal,
            json.ordenCompra,
            json.ModeloId,
            json.rechazoPreOrden,
            json.motivoRechazoPreOrden,
            json.facturaVehiculo,
            json.rechazadoFacturaVehiculo,
            json.solvenciaPNC,
            json.rechazadoSolvenciaPNC,
            json.garantiaMobiliariaSAT,
            json.rechazadoGarantiaMobiliariaSAT,
            json.registroGarantiaMobiliariaGob,
            json.rechazadoRegistroGarantiaMobiliariaGob,
            json.cheque,
            json.rechazadoCheque,
            json.memoCompraDirecta,
            json.rechazadoMemoCompraDirecta,
            json.promesaDeuda,
            json.rechazadoPromesaDeuda,
            json.cartaAutorizacionPagoDeuda,
            json.rechazadoCartaAutorizacionPagoDeuda,
            json.correoBancoValidarSaldoPago,
            json.facturaDelCliente,
            json.rechazadoFacturaDelCliente,
            json.aplicaFiniquitoLiberacionPrenda,
            json.finiquito,
            json.rechazadoFiniquito,
            json.liberacionPrenda,
            json.rechazadoLiberacionPrenda,
            json.patenteComercio,
            json.rechazadoPatenteComercio,
            json.patenteSociedad,
            json.rechazadoPatenteSociedad,
            json.facturaEmpresaVendeValorAvaluo,
            json.rechazadoFacturaEmpresaVendeValorAvaluo,
            json.copiaCheque,
            json.rechazadoCopiaCheque,
            json.rechazoDocumentacionCompleta,
            json.motivoRechazoDocumentacionCompleta,
            json.fechaTentativaFirmaPromesa,
            json.fechaTentativaFirmaTraspaso,
            json.horaTentativaFirmaPromesa,
            json.horaTentativaFirmaTraspaso,
            json.observacionesDocumentacionFinal,
            json.abogadoFirmaPromesa,
            json.abogadoFirmaTraspaso,
            json.fechaFirmaPromesa,
            json.horaFirmaPromesa,
            json.fechaFirmaTraspaso,
            json.horaFirmaTraspaso,
            json.documentoMemo,
            json.rechazadoDocumentoMemo,

            //Relaciones
            json.EmpresaId,
            json.VendedorId,
            json.MarcaId,
            json.LineaId,
        );

        if (json.Empresa) {
            registro.empresa = this.empresaDeJSON(json.Empresa);
        }

        if (json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.Marca) {
            registro.marca = this.marcaVehiculoDeJSON(json.Marca);
        }

        if (json.Linea) {
            registro.linea = this.lineaVehiculoDeJSON(json.Linea);
        }

        if (json.fechaSolicitud) {
            registro.fechaSolicitud = this.convertirFecha(json.fechaSolicitud,'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaSolicitudFormato = this.convertirFecha(json.fechaSolicitud,'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaSolicitudDate = this.convertirFecha(json.fechaSolicitud, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaTentativaFirmaPromesa) {
            registro.fechaTentativaFirmaPromesa = this.convertirFecha(json.fechaTentativaFirmaPromesa,'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaTentativaFirmaPromesaFormato = this.convertirFecha(json.fechaTentativaFirmaPromesa,'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaTentativaFirmaPromesaDate = this.convertirFecha(json.fechaTentativaFirmaPromesa, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaTentativaFirmaTraspaso) {
            registro.fechaTentativaFirmaTraspaso = this.convertirFecha(json.fechaTentativaFirmaTraspaso,'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaTentativaFirmaTraspasoFormato = this.convertirFecha(json.fechaTentativaFirmaTraspaso,'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaTentativaFirmaTraspasoDate = this.convertirFecha(json.fechaTentativaFirmaTraspaso, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaFirmaPromesa) {
            registro.fechaFirmaPromesa = this.convertirFecha(json.fechaFirmaPromesa,'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFirmaPromesaFormato = this.convertirFecha(json.fechaFirmaPromesa,'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaFirmaPromesaDate = this.convertirFecha(json.fechaFirmaPromesa, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaFirmaTraspaso) {
            registro.fechaFirmaTraspaso = this.convertirFecha(json.fechaFirmaTraspaso,'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFirmaTraspasoFormato = this.convertirFecha(json.fechaFirmaTraspaso,'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaFirmaTraspasoDate = this.convertirFecha(json.fechaFirmaTraspaso, 'YYYY-MM-DD', 'date');
        }
        
        return registro;
    }

    //CambioEstadoSolicitudTraspaso
    public cambioEstadoSolicitudTraspasoDeJSON = (json: any): CambioEstadoSolicitudTraspaso => {
        if (!json) return null;
        var registro = new CambioEstadoSolicitudTraspaso(
            json.id,
            json.fecha,
            json.estadoAnterior,
            json.estadoPosterior,
            json.observaciones,

            //Relaciones
            json.VendedorId,
            json.SolicitudTraspasoId,
        );

        if (json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.SolicitudTraspaso) {
            registro.solicitudTraspaso = this.solicitudTraspasoDeJSON(json.SolicitudTraspaso);
        }

        if (json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha,'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha,'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    // Historial Firma Promesa
    public historialFirmaPromesaDeJSON = (json: any): HistorialFirmaPromesa => {
        if (!json) return null;
        var registro = new HistorialFirmaPromesa(
            json.id,
            json.fechaCreacion,
            json.fechaFirmaPromesa,
            json.horaFirmaPromesa,

            //Relaciones
            json.SolicitudTraspasoId,
            json.UsuarioId,
        );

        if (json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.SolicitudTraspaso) {
            registro.solicitudTraspaso = this.solicitudTraspasoDeJSON(json.SolicitudTraspaso);
        }

        if (json.fechaCreacion) {
            registro.fechaCreacion = this.convertirFecha(json.fechaCreacion,'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaCreacionFormato = this.convertirFechaATimezone(json.fechaCreacion,'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaCreacionDate = this.convertirFecha(json.fechaCreacion, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaFirmaPromesa) {
            registro.fechaFirmaPromesa = this.convertirFecha(json.fechaFirmaPromesa,'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFirmaPromesaFormato = this.convertirFecha(json.fechaFirmaPromesa,'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaFirmaPromesaDate = this.convertirFecha(json.fechaFirmaPromesa, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    // Historial Firma Traspaso
    public historialFirmaTraspasoDeJSON = (json: any): HistorialFirmaTraspaso => {
        if (!json) return null;
        var registro = new HistorialFirmaTraspaso(
            json.id,
            json.fechaCreacion,
            json.fechaFirmaTraspaso,
            json.horaFirmaTraspaso,

            //Relaciones
            json.SolicitudTraspasoId,
            json.UsuarioId,
        );

        if (json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.SolicitudTraspaso) {
            registro.solicitudTraspaso = this.solicitudTraspasoDeJSON(json.SolicitudTraspaso);
        }

        if (json.fechaCreacion) {
            registro.fechaCreacion = this.convertirFecha(json.fechaCreacion,'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaCreacionFormato = this.convertirFechaATimezone(json.fechaCreacion,'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaCreacionDate = this.convertirFecha(json.fechaCreacion, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaFirmaTraspaso) {
            registro.fechaFirmaTraspaso = this.convertirFecha(json.fechaFirmaTraspaso,'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFirmaTraspasoFormato = this.convertirFecha(json.fechaFirmaTraspaso, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaFirmaTraspasoDate = this.convertirFecha(json.fechaFirmaTraspaso, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    // Documento Unidad
    public documentoUnidadDeJSON = (json: any): DocumentoUnidad => {
        if (!json) return null;
        var registro = new DocumentoUnidad(
            json.id,
            json.descripcion,
            json.fechaSubida,
            json.url,
            //Relaciones
            json.UsuarioId,
            json.UnidadId,
        );

        if (json.Unidad) {
            registro.Unidad = this.unidadDeJSON(json.Unidad);
        }

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.fechaSubida) {
            registro.fechaSubida = this.convertirFecha(json.fechaSubida, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaSubidaFormato = this.convertirFecha(json.fechaSubida, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaSubidaDate = this.convertirFecha(json.fechaSubida, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    // imagenUnidad
    public imagenUnidadDeJSON = (json: any): ImagenUnidad => {
        if (!json) return null;
        var registro = new ImagenUnidad(
            json.id,
            json.descripcion,
            json.fechaSubida,
            json.url,
            //Relaciones
            json.UsuarioId,
            json.UnidadId,
        );

        if (json.Unidad) {
            registro.Unidad = this.unidadDeJSON(json.Unidad);
        }

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
        }


        if (json.fechaSubida) {
            registro.fechaSubida = this.convertirFecha(json.fechaSubida, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaSubidaFormato = this.convertirFecha(json.fechaSubida, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaSubidaDate = this.convertirFecha(json.fechaSubida, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    public pendienteUnidadDeJSON = (json: any): PendienteUnidad => {
        if (!json) return null;
        var registro = new PendienteUnidad(
            json.id,
            json.descripcion,
            json.fechaCreacion,
            json.prioridad,
            json.estado,
            json.mecanico,
            //Relaciones
            json.UsuarioId,
            json.UnidadId,
        );

        if (json.Unidad) {
            registro.Unidad = this.unidadDeJSON(json.Unidad);
            let estadoObj = this.apiURLs.estadoUnidad.find((estado) => estado.id == registro.Unidad.estado);
            if(estadoObj) registro.estadoUnidadFormato = estadoObj.nombre;
            else registro.estadoUnidadFormato = registro.Unidad.estado;
        }

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
            registro.nombreUsuario = registro.Usuario.nombreCompleto;
        }

        if(json.ProximoContratoAhorrent) {
            registro.proximoContrato = this.contratoAhorrentDeJSON(json.ProximoContratoAhorrent);
            registro.fechaProximaReservaFormato = registro.proximoContrato.fechaInicioFormato;
        }

        if(registro.prioridad) {
            let index = this.indexOf(this.apiURLs.prioridadesPendientesUnidad, 'id', registro.prioridad);
            if (index != -1) registro.prioridadFormato = this.apiURLs.prioridadesPendientesUnidad[index].nombre;
        }

        if(registro.estado) {
            let index = this.indexOf(this.apiURLs.estadosPendientesUnidad, 'id', registro.estado);
            if (index != -1) registro.estadoFormato = this.apiURLs.estadosPendientesUnidad[index].nombre;
        }

        if (json.fechaCreacion) {
            registro.fechaCreacion = this.convertirFecha(json.fechaCreacion, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaCreacionFormato = this.convertirFecha(json.fechaCreacion, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaCreacionDate = this.convertirFecha(json.fechaCreacion, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    // PDI
    public configuracionPDIDeJSON = (json: any): ConfiguracionPDI => {
        if (!json) return null;
        var registro = new ConfiguracionPDI(
            json.id,
            json.notificacionesCreacionSolicitud,
            json.notificacionesAutorizacionSolicitud,
            json.notificacionesPasoKanboard,
            json.notificacionesAccesoriosFueraAcuerdo,
            json.tiposVenta,
            json.notificarDocumentosCompletos,
            json.notificacionesCreacionExhibicion,
            json.sucursales,
            json.tiposPases,
            json.usuariosNotificacionesKanboard,
            json.emailsReporteria,
            json.horarioReportes,
            json.ultimaActualizacionDatosVentas,
            json.historialActualizacionesDatosVentas,
            json.horasBloqueoInspeccionSalida,
            json.horasBloqueoInspeccionEntrada,
            json.estadoLlantas,
            json.nivelGasolina,
            json.entregadoPor,
            json.sucursalesInspeccionSalida,
            json.recibidoPor,
            json.sucursalesInspeccionEntrada,
            json.tiposDanios,
            json.calificacionPDI,
            json.horasBloqueoInspeccionEntradaYSalida,
            //Relaciones
            json.EmpresaId,
        );

        if (json.ultimaActualizacionDatosVentas) {
            registro.ultimaActualizacionDatosVentasFormato = this.convertirFechaATimezone(json.ultimaActualizacionDatosVentas, 'DD/MM/YYYY HH:mm:ss', this.apiURLs.defaultTimezone);
        }

        return registro;
    }


    solicitudPDIDeJSON = (json: any): SolicitudPDI => {
        if (!json) return null;
        var registro = new SolicitudPDI(
            json.id,
            json.numeroInventario,
            json.fechaActual,
            json.tipoVenta,
            json.porcentajeEnganche,
            json.excepcion,
            json.observaciones,
            json.fechaPedidoVehiculo,
            json.estado,
            json.accesoriosFueraAcuerdo,
            json.enviadoAPlacas,
            json.vendedor,
            json.inventario,
            json.chasis,
            json.color,
            json.linea,
            json.cliente,
            json.ubicacionAlFacturar,
            json.polarizado,
            json.cuero,
            json.tipoNegocio,
            json.accesorios,
            json.N_factura,
            json.fechaFactura,
            json.fechaCreacion,
            json.numeroTareaKanboard,
            json.numeroPlaca,
            json.observacionesDatoVenta,
            json.fechaEntregaCliente,
            json.fechaEntregadoCliente,
            json.horaEntregadoCliente,
            json.documentoFactura,
            json.documentoRecibo,
            json.documentoAcuerdoVenta,
            json.fechaLimite,
            json.tipo,
            json.numeroFacturaImportacion,
            json.estadoCodigo,
            json.categoria,
            json.modelo,
            json.numeroPedido,
            json.serieCodigo,
            json.fechaEmbarque,
            json.sucursales,
            json.compania,
            json.tipoPase,
            json.inspeccionSalida,
            json.inspeccionEntrada,
            json.calificacionPDI,
            //Relaciones
            json.UsuarioId,
            json.EmpresaId,
        )

        if (json.Empresa) {
            registro.Empresa = this.empresaDeJSON(json.Empresa);
        }
        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
        }
        if (json.fechaFactura) {
            registro.fechaFactura = this.convertirFecha(json.fechaFactura, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFacturaFormato = this.convertirFecha(json.fechaFactura, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaFacturaDate = this.convertirFecha(json.fechaFactura, 'YYYY-MM-DD', 'date');
        }
        if (json.fechaCreacion) {
            registro.fechaCreacion = this.convertirFecha(json.fechaCreacion, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaCreacionFormato = this.convertirFecha(json.fechaCreacion, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaCreacionDate = this.convertirFecha(json.fechaCreacion, 'YYYY-MM-DD', 'date');
        }
        if (json.fechaPedidoVehiculo) {
            registro.fechaPedidoVehiculo = this.convertirFecha(json.fechaPedidoVehiculo, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaPedidoVehiculoFormato = this.convertirFecha(json.fechaPedidoVehiculo, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaPedidoVehiculoDate = this.convertirFecha(json.fechaPedidoVehiculo, 'YYYY-MM-DD', 'date');
        }
        if (json.fechaActual) {
            registro.fechaActual = this.convertirFecha(json.fechaActual, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaActualFormato = this.convertirFecha(json.fechaActual, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaActualDate = this.convertirFecha(json.fechaActual, 'YYYY-MM-DD', 'date');
        }
        if (json.fechaEntregaCliente) {
            registro.fechaEntregaCliente = this.convertirFecha(json.fechaEntregaCliente, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaEntregaClienteFormato = this.convertirFecha(json.fechaEntregaCliente, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaEntregaClienteDate = this.convertirFecha(json.fechaEntregaCliente, 'YYYY-MM-DD', 'date');
        }
        if (json.fechaEntregadoCliente) {
            registro.fechaEntregadoCliente = this.convertirFecha(json.fechaEntregadoCliente, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaEntregadoClienteFormato = this.convertirFecha(json.fechaEntregadoCliente, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaEntregadoClienteDate = this.convertirFecha(json.fechaEntregadoCliente, 'YYYY-MM-DD', 'date');
        }
        if (json.fechaLimite) {
            registro.fechaLimite = this.convertirFecha(json.fechaLimite, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaLimiteFormato = this.convertirFecha(json.fechaLimite, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaLimiteDate = this.convertirFecha(json.fechaLimite, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaEmbarque) {
            registro.fechaEmbarque = this.convertirFecha(json.fechaEmbarque, 'YYYY/MM/DD', 'YYYY-MM-DD');
            registro.fechaEmbarqueFormato = this.convertirFecha(json.fechaEmbarque, 'YYYY/MM/DD', 'DD/MM/YYYY');
            registro.fechaEmbarqueDate = this.convertirFecha(registro.fechaEmbarque, 'YYYY/MM/DD', 'date');
        }

        // Inspecciones
        if (json.inspeccionEntrada) {
            registro.inspeccionEntradaJson = JSON.parse(json.inspeccionEntrada);
            registro.inspeccionEntradaJson.inspeccionesDetalladasJson = registro.inspeccionEntradaJson.inspeccionesDetalladasJson ? registro.inspeccionEntradaJson.inspeccionesDetalladasJson : new InspeccionesDetalladasPDI();
        }

        if (json.inspeccionSalida) {
            registro.inspeccionSalidaJson = JSON.parse(json.inspeccionSalida);
            registro.inspeccionSalidaJson.inspeccionesDetalladasJson = registro.inspeccionSalidaJson.inspeccionesDetalladasJson ? registro.inspeccionSalidaJson.inspeccionesDetalladasJson : new InspeccionesDetalladasPDI();
        }

        if(json.DanioSolicitudPDIs) {
            registro.DaniosSolicitudesPDI = json.DanioSolicitudPDIs.map(element => this.danioSolicitudPDIDeJSON(element));
        }

        return registro;
    }

    public cambioEstadoSolicitudPDIDeJSON = (json: any): CambioEstadoSolicitudPDI => {
        if (!json) return null;
        var registro = new CambioEstadoSolicitudPDI(
            json.id,
            json.fecha,
            json.estadoAnterior,
            json.estadoPosterior,
            json.SolicitudPDIId,
            json.UsuarioId,
        );

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.SolicitudPDI) {
            registro.SolicitudPDI = this.solicitudPDIDeJSON(json.solicitudPDI);
        }

        if (json.fecha) {
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if (json.estadoAnterior) {
            let index = this.indexOf(this.apiURLs.estadosSolicitudPDI, 'id', json.estadoAnterior);
            if (index != -1) registro.estadoAnteriorFormat = this.apiURLs.estadosSolicitudPDI[index].nombre;
        }

        if (json.estadoPosterior) {
            let index = this.indexOf(this.apiURLs.estadosSolicitudPDI, 'id', json.estadoPosterior);
            if (index != -1) registro.estadoPosteriorFormat = this.apiURLs.estadosSolicitudPDI[index].nombre;
        }

        return registro;
    }

    public danioSolicitudPDIDeJSON = (json: any): DanioSolicitudPDI => {
        if (!json) return null;
        var registro = new DanioSolicitudPDI(
            json.id,
            json.tipo,
            json.parteVehiculo,
            json.fecha,
            json.tipoDanio,
            json.descripcion,
            json.imagenes,
            json.SolicitudPDIId,
            json.UsuarioId,
        );

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.SolicitudPDI) {
            registro.SolicitudPDI = this.solicitudPDIDeJSON(json.solicitudPDI);
        }

        if (json.fecha) {
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    public danioAhorrentDeJSON = (json: any): DanioAhorrent => {
        if (!json) return null;
        var registro = new DanioAhorrent(
            json.id,
            json.tipo,
            json.parteVehiculo,
            json.fecha,
            json.tipoDanio,
            json.descripcion,
            json.imagenes,
            json.UnidadId,
            json.ContratoAhorrentId,
            json.UsuarioId,
        );

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
            registro.nombreUsuario = registro.Usuario.nombreCompleto;
        }

        if (json.Unidad) {
            registro.Unidad = this.unidadDeJSON(json.Unidad);
            registro.numeroUnidad = registro.Unidad.numero;
        }

        if (json.ContratoAhorrent) {
            registro.contrato = this.contratoAhorrentDeJSON(json.ContratoAhorrent);
            registro.numeroContrato = registro.contrato.correlativo;
            registro.nombreCliente = registro.contrato.nombreCliente;
        }

        if (json.fecha) {
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if(json.parteVehiculo) {
            let parteObj = this.apiURLs.inspeccionGrafica.find((parte) => parte.id == json.parteVehiculo);
            if(parteObj) {
                registro.nombreParteVehiculo = parteObj.nombre;
            }
            else registro.nombreParteVehiculo = json.parteVehiculo;
        }

        return registro;
    }

    public datoVentaDeJSON = (json: any): DatoVenta => {
        if (!json) return null;
        var registro = new DatoVenta(
            json.id,
            json.vendedor,
            json.inventario,
            json.chasis,
            json.color,
            json.linea,
            json.cliente,
            json.ubicacionAlFacturar,
            json.polarizado,
            json.cuero,
            json.tipoNegocio,
            json.accesorios,
            json.numeroFactura,
            json.fechaFactura,
            json.observaciones,
            json.numeroAcuerdo,
            json.ventaValidada,
            json.tipo,
            json.numeroFacturaImportacion,
            json.estadoCodigo,
            json.categoria,
            json.modelo,
            json.numeroPedido,
            json.serieCodigo,
            json.fechaEmbarque,
            json.compania,
        );

        if (json.fechaFactura) {
            registro.fechaFactura = this.convertirFecha(json.fechaFactura, 'YYYY/MM/DD', 'YYYY-MM-DD');
            registro.fechaFacturaFormato = this.convertirFecha(json.fechaFactura, 'YYYY/MM/DD', 'DD/MM/YYYY');
            registro.fechaFacturaDate = this.convertirFecha(registro.fechaFactura, 'YYYY/MM/DD', 'date');
        }

        if (json.fechaEmbarque) {
            registro.fechaEmbarque = this.convertirFecha(json.fechaEmbarque, 'YYYY/MM/DD', 'YYYY-MM-DD');
            registro.fechaEmbarqueFormato = this.convertirFecha(json.fechaEmbarque, 'YYYY/MM/DD', 'DD/MM/YYYY');
            registro.fechaEmbarqueDate = this.convertirFecha(registro.fechaEmbarque, 'YYYY/MM/DD', 'date');
        }

        return registro;
    }

    public facturaSVDeJSON = (json: any): FacturaSV => {
        if (!json) return null;
        var registro = new FacturaSV(
            json.id,
            json.tipo,
            json.numeroFactura,
            json.tipoFacturacion,
            json.codigoCliente,
            json.nitCliente,
            json.razonSocialCliente,
            json.ubicacionVenta,
            json.codigoBodega,
            json.descripcionBodega,
            json.codigoVendedor,
            json.descripcionVendedor,
            json.tipoVendedor,
            json.fechaFacturacion,
            json.conteoVehiculos,
            json.nombreCompania,
            json.correo1,
            json.direccion,
            json.cajero,
            json.tipoVenta,
            json.codigoPostal,
            json.actividadEconomica,
            json.estadoFEL,
            json.codigoGeneracion,
            json.fechaEmision,
            json.identificador,
            json.numeroControl,
            json.selloRecepcion,
            json.rutaPDF,
            json.motivoAnulacion,
            json.codigoGeneracionAnulacion,
            json.fechaEmisionAnulacion,
            json.selloRecepcionAnulacion,
            json.numeroControlAnulacion,
            json.rutaPDFAnulacion,
            json.nrc,
            json.empresa,
            json.jsonEmision,
        );

        if (json.fechaFacturacion) {
            registro.fechaFacturacion = this.convertirFecha(json.fechaFacturacion, 'YYYY/MM/DD', 'YYYY-MM-DD');
            registro.fechaFacturacionFormato = this.convertirFecha(json.fechaFacturacion, 'YYYY/MM/DD', 'DD/MM/YYYY');
            registro.fechaFacturacionDate = this.convertirFecha(registro.fechaFacturacion, 'YYYY/MM/DD', 'date');
        }

        if(json.estadoFEL) {
            let estadoObj = this.apiURLs.estadosFacturasSV.find(x => x.id == json.estadoFEL);
            if(estadoObj) {
                registro.nombreEstadoFEL = estadoObj.nombre;
                registro.colorEstadoFEL = estadoObj.color;
            }
        }

        if(json.tipo) {
            let tipoObj = this.apiURLs.tiposFacturasSV.find(x => x.id == json.tipo);
            if(tipoObj) {
                registro.nombreTipo = tipoObj.nombre;
            }
        }

        if(json.DetalleFacturaSVs) {
            registro.detalles = [];
            for (const el of json.DetalleFacturaSVs) {
                let detalle = this.detalleFacturaSVDeJSON(el);
                registro.detalles.push(detalle);
                registro.numeroReferencia = detalle.noReferencia;
            }
        }

        if(json.UltimaTransactionFEL) {
            registro.ultimaTransaccion = this.transactionFELDeJSON(json.UltimaTransactionFEL);
        }

        return registro;
    }

    public detalleFacturaSVDeJSON = (json: any): DetalleFacturaSV => {
        if (!json) return null;
        var registro = new DetalleFacturaSV(
            json.id,
            json.numeroFactura,
            json.codigoMarca,
            json.descripcionMarca,
            json.inventario,
            json.chasis,
            json.facturadoValorNeto,
            json.facturadoIVA,
            json.facturadoTotal,
            json.descuento,
            json.retencion,
            json.textoRetencion,
            json.tipoVehiculo,
            json.noReferencia,
            json.codigoNuevoUsado,
            json.descripcionNuevoUsado,
            json.codigoSerie,
            json.serieMadre,
            json.serieDescripcion,
            json.modelo,
            json.motor,
            json.color,
            json.combustible,
            json.transmision,
            json.tonelaje,
            json.observaciones,
            json.unidades,
            json.codigoProducto,
            json.descripcionProducto,
            json.desplazamiento,
            json.FacturaSVId,
        );

        return registro;
    }

    public transactionFELDeJSON = (json: any): TransactionFEL => {
        if (!json) return null;
        var registro = new TransactionFEL(
            json.id,
            json.idFel,
            json.error,
            json.fecha,
            json.descripcion,
            json.alertasSat,
            json.alertasInFile,
            json.errores,
            json.respuestaJson,
            json.tipo,
            json.randomNumber,
            json.dataJSON,
            json.idRelacionado,
            json.EmpresaId,
        );

        if(json.fecha) {
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    public valorFacturaEspecialDeJSON = (json: any): ValorFacturaEspecial => {
        if (!json) return null;
        var registro = new ValorFacturaEspecial(
            json.id,
            json.tipo,
            json.valor,
            json.EmpresaId,
        );

        return registro;
    }

    public cajeroFacturaSVDeJSON = (json: any): CajeroFacturaSV => {
        if (!json) return null;
        var registro = new CajeroFacturaSV(
            json.id,
            json.numero,
            json.dui,
            json.nombreCompleto,
            json.sucursal,
            json.EmpresaId,
        );
        return registro;
    }

    public configuracionFacturaSVDeJSON = (json: any): ConfiguracionFacturaSV => {
        if (!json) return null;
        var registro = new ConfiguracionFacturaSV(
            json.id,
            json.ultimaActualizacionFacturasSV,
            json.historialActualizacionesFacturasSV,
            //Relaciones
            json.EmpresaId,
        );

        if (json.ultimaActualizacionFacturasSV) {
            registro.ultimaActualizacionFacturasSVFormato = this.convertirFechaATimezone(json.ultimaActualizacionFacturasSV, 'DD/MM/YYYY HH:mm:ss', this.apiURLs.defaultTimezone);
        }

        

        return registro;
    }

    /* Presupuestos */
    //Clientes presupuestos
    public clientePresupuestoDeJSON = (json: any): ClientePresupuesto => {
        if (!json) return null;
        var registro = new ClientePresupuesto(
            json.id,
            json.nombres,
            json.apellidos,
            json.nombreCompleto,
            json.dpi,
            json.email,
            json.telefono,
            json.activo,
            json.eliminado,
            json.nit,
            //Relaciones
            json.EmpresaId,
        );

        registro.nombreCompleto = (json.nombres ? json.nombres : '') + ' ' + (json.apellidos ? json.apellidos : '');
        registro.nombreCompletoNit = (json.nombres ? json.nombres : '') + ' ' + (json.apellidos ? json.apellidos : '') + ' (' + (json.nit ? json.nit : '') + ')';
        registro.nombreCompletoNitDireccion = (json.nombres ? json.nombres : '') + ' ' + (json.apellidos ? json.apellidos : '') + ' (' + (json.nit ? json.nit : '') + ') - (' + (json.direccion ? json.direccion : '') + ')';

        if (json.Empresa) {
            registro.Empresa = this.empresaDeJSON(json.Empresa);
        }

        return registro;
    }

    // Configuracion presupuesto
    public configuracionPresupuestoDeJSON = (json: any): ConfiguracionPresupuesto => {
        if (!json) return null;
        var registro = new ConfiguracionPresupuesto(
            json.id,
            json.asesores,
            json.tiposVehiculos,
            json.lineas,
            json.supervisores,
            json.mecanicos,
            json.sedes,
            json.notificacionesPresupuestos,
            json.porcentajesDescuentos,
            json.motivosRechazo,
            json.notificacionManoObra,
            json.notificacionRepuestos,
            json.montoAutorizadoPresupuesto,
            json.emailsDescargaVisualizacionPresupuesto,
            json.notificacionesFinalizar,
            json.porcentajesDescuentosRepuestos,
            json.notificacionRepuestoExterno,
            json.EmpresaId)

        if (json.Empresa) {
            registro.Empresa = this.empresaDeJSON(json.Empresa);
        }

        return registro;
    }


    // Presupuestos
    public presupuestoDeJSON = (json: any): Presupuesto => {
        if (!json) return null;
        var registro = new Presupuesto(
            json.id,
            json.fecha,
            json.numeroOrdenIntegra,
            json.asesor,
            json.numeroPlaca,
            json.tipoVehiculo,
            json.LineaVehiculo,
            json.anioVehiculo,
            json.kilometraje,
            json.supervisor,
            json.mecanico,
            json.sede,
            json.totalManoObra,
            json.totalDescuentoManoObra,
            json.totalRepuestos,
            json.totalDescuentoRepuestos,
            json.total,
            json.estado,

            // fechas
            json.fechaInicioSeccion1,
            json.fechaFinSeccion1,
            json.fechaEnvioNotificacionSeccion1,
            json.fechaInicioSeccion2,
            json.fechaFinSeccion2,
            json.fechaEnvioNotificacionSeccion2,
            json.fechaInicioSeccion3,
            json.fechaFinSeccion3,
            json.fechaEnvioNotificacionSeccion3,
            json.fechaInicioSeccion4,
            json.fechaFinSeccion4,
            json.fechaInicioSeccion6,
            json.fechaFinSeccion6,
            json.fechaInicioSeccion7,
            json.fechaFinSeccion7,

            json.observaciones,
            json.numeroPresupuestoIntegra,
            json.validarPresupuesto,
            json.mostrarItemRechazados,

            json.ClientePresupuestoId,
            json.EmpresaId,
            json.UsuarioId,
        );

        if (json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY/MM/DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY/MM/DD', 'DD/MM/YYYY');
            registro.fechaDate = this.convertirFecha(registro.fecha, 'YYYY/MM/DD', 'date');
        }

        if (json.ClientePresupuesto) {
            registro.ClientePresupuesto = this.clientePresupuestoDeJSON(json.ClientePresupuesto);
        }

        if (json.Empresa) {
            registro.Empresa = this.empresaDeJSON(json.Empresa);
        }

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
        }


        registro.puntosPresupuesto = [];
        if (json.puntosPresupuesto && Array.isArray(json.puntosPresupuesto)) {
            for (let punto of json.puntosPresupuesto) {
                registro.puntosPresupuesto.push(this.puntoPresupuestoDeJSON(punto));
            }
            //ordenamiento
            registro.puntosPresupuesto.sort(function (a, b) { return a.id - b.id })

        }

        return registro;
    }


    // Cambio estado presupuestos
    public cambioEstadoPresupuestoDeJSON = (json: any): CambioEstadoPresupuesto => {
        if (!json) return null;
        var registro = new CambioEstadoPresupuesto(
            json.id,
            json.fecha,
            json.estadoAnterior,
            json.estadoPosterior,
            json.observaciones,
            json.tiempoTranscurrido,
            //Relaciones
            json.UsuarioId,
            json.PresupuestoId,
        );

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.Presupuesto) {
            registro.Presupuesto = this.presupuestoDeJSON(json.Presupuesto);
        }

        if (json.fecha) {
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }


    // Punto presupuesto
    public puntoPresupuestoDeJSON = (json: any): PuntoPresupuesto => {
        if (!json) return null;
        var registro = new PuntoPresupuesto(
            json.id,
            json.prioridad,
            json.porcentajeDescuento,
            json.totalManoObra,
            json.totalRepuestos,
            json.total,
            json.totalDescuentoManoObra,
            json.totalDescuentoRepuestos,
            json.PresupuestoId,
        );

        if (json.Presupuesto) {
            registro.Presupuesto = this.presupuestoDeJSON(json.Presupuesto);
        }

        registro.ItemsPuntoPresupuesto = [];
        if (json.ItemsPuntoPresupuesto && Array.isArray(json.ItemsPuntoPresupuesto)) {
            for (let item of json.ItemsPuntoPresupuesto) {
                registro.ItemsPuntoPresupuesto.push(this.itemPuntoPresupuestoDeJSON(item));
            }
            //ordenamiento
            registro.ItemsPuntoPresupuesto.sort(function (a, b) { return a.id - b.id })
        }

        return registro;
    }


    // Item punto presupuesto
    public itemPuntoPresupuestoDeJSON = (json: any): ItemPuntoPresupuesto => {
        if (!json) return null;
        var registro = new ItemPuntoPresupuesto(
            json.id,
            json.tipo,
            json.codigo,
            json.descripcion,
            json.precio,
            json.rechazado,
            json.motivoRechazo,
            json.porcentajeDescuento,
            json.presupuestoRecuperado,
            json.manoObraExterna,
            json.repuestoExterno,
            json.PresupuestoId,
            json.PuntoPresupuestoId,
            json.ItemPuntoPresupuestoId,
        );

        if (json.PuntoPresupuesto) {
            registro.PuntoPresupuesto = this.puntoPresupuestoDeJSON(json.PuntoPresupuesto);
        }

        if (json.ItemPuntoPresupuesto) {
            registro.ItemPuntoPresupuesto = this.itemPuntoPresupuestoDeJSON(json.ItemPuntoPresupuesto);
        }

        if (json.Presupuesto) {
            registro.Presupuesto = this.presupuestoDeJSON(json.Presupuesto);
        }

        return registro;
    }

    // Item punto presupuesto
    public repuestoItemPuntoPresupuestoDeJSON = (json: any): RepuestoItemPuntoPresupuesto => {
        if (!json) return null;
        var registro = new RepuestoItemPuntoPresupuesto(
            json.id,
            json.tipo,
            json.codigo,            
            json.descripcion,
            json.precio,
            json.rechazado,
            json.motivoRechazo,
            json.repuestoImportado,
            json.repuestoBodegaLocal,
            json.bodegaLocalTaller,
            json.PresupuestoId,
            json.PuntoPresupuestoId,
            json.ItemPuntoPresupuestoId,
        );

        if (json.PuntoPresupuesto) {
            registro.PuntoPresupuesto = this.puntoPresupuestoDeJSON(json.PuntoPresupuesto);
        }

        if (json.ItemPuntoPresupuesto) {
            registro.ItemPuntoPresupuesto = this.itemPuntoPresupuestoDeJSON(json.ItemPuntoPresupuesto);
        }

        if(json.Presupuesto){
            registro.Presupuesto = this.presupuestoDeJSON(json.Presupuesto);
        }

        return registro;
    }

    public historicoItemPresupuestoDeJSON = (json: any): HistoricoItemPresupuesto => {
        if (!json) return null;
        var registro = new HistoricoItemPresupuesto(
            json.id,
            json.fecha,
            json.tipo,
            json.accion,
            json.PresupuestoId,
            json.PuntoPresupuestoId,
            json.ItemPuntoPresupuestoId,
            json.UsuarioId
        );

        if (json.PuntoPresupuesto) {
            registro.PuntoPresupuesto = this.puntoPresupuestoDeJSON(json.PuntoPresupuesto);
        }

        if (json.ItemPuntoPresupuesto) {
            registro.ItemPuntoPresupuesto = this.itemPuntoPresupuestoDeJSON(json.ItemPuntoPresupuesto);
        }

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.Presupuesto) {
            registro.Presupuesto = this.presupuestoDeJSON(json.Presupuesto);
        }

        if (json.fecha) {
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }
        return registro;
    }

    // * * * Requisiciones * * *
    public configuracionRequisicionDeJSON = (json: any): ConfiguracionRequisicion => {
        if (!json) return null;
        var registro = new ConfiguracionRequisicion(
            json.id,
            json.costoTransporte,
            json.factorAMarginar,
            json.tasaCambio,
            json.vehiculos,
            json.vias,
            json.paisOrigen,
            json.factorEspecial,
            json.semaforoDiasPedidosAbiertos,
            json.semaforoDiasHastaEntrega,
            json.semaforoDiasPendienteEntrega,
            
            //Relaciones
            json.EmpresaId,
        );

        if (json.Empresa) {
            registro.Empresa = this.empresaDeJSON(json.Empresa);
        }

        return registro;
    }

    public requisicionDeJSON = (json: any): Requisicion => {
        if (!json) return null;
        var registro = new Requisicion(
            json.id,
            json.estado,
            json.fecha,
            json.fechaAprobacion,
            json.nit,
            json.cliente,
            json.placa,
            json.chasis,
            json.vehiculo,
            json.modelo,
            json.etapaTaller,
            json.canal,
            json.tipoEnvio,
            json.boleta,
            json.pedidoSistema,
            json.pedidoFabrica,
            json.kilometraje,
            json.vehiculoGarantia,
            json.pendienteCotizacionURL,
            json.observaciones,
            json.UsuarioId,
        );

        if (json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaAprobacion) {
            registro.fechaAprobacion = this.convertirFecha(json.fechaAprobacion, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaAprobacionFormato = this.convertirFecha(json.fechaAprobacion, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaAprobacionDate = this.convertirFecha(json.fechaAprobacion, 'YYYY-MM-DD', 'date');
        }
        if (json.DetalleRequisicions) {
            registro.DetallesRequisicion = json.DetalleRequisicions.map((detalle: any) => this.detalleRequisicionDeJSON(detalle));
        }

        if (json.CambioEstadoRequisicions) {
            registro.CambioEstadoRequisicion = json.CambioEstadoRequisicions.map((cambio: any) => this.cambioEstadoRequisicionDeJSON(cambio));
        }

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
        }

        return registro;
    }

    public cambioEstadoRequisicionDeJSON = (json: any): CambioEstadoRequisicion => {
        if (!json) return null;
        var registro = new CambioEstadoRequisicion(
            json.id,
            json.fecha,
            json.estadoAnterior,
            json.estadoPosterior,
            json.observaciones,
            json.RequisicionId,
            json.UsuarioId,
        );

        if (json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.Requisicion) {
            registro.requisicion = this.requisicionDeJSON(json.Requisicion);
        }

        if (json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    public detalleRequisicionDeJSON = (json: any): DetalleRequisicion => {
        if (!json) return null;
        var registro = new DetalleRequisicion(
            json.id,
            json.cantidad,
            json.codigo,
            json.descripcion,
            json.valorFOB,
            json.envio,
            json.pais,
            json.entregaDias,
            json.fecha,
            json.poliza,
            json.ingreso,
            json.landedCost,
            json.precioUnitario,
            json.precioTotal,
            json.autorizado,
            json.codigoVelocidad,
            json.pedidoSistema,
            json.pedidoFabrica,
            json.comentario,
            json.precioSinIVA,
            json.valorQuetzales,
            json.factorGanancia,
            json.precioConIVA,
            json.descuento,
            json.total,
            json.despachado,
            json.backorder,
            json.placa,
            json.chasis,
            json.vehiculo,
            json.modelo,
            json.sucursal,
            json.observacionVehiculo,
            json.facturado,
            json.fechaFacturado,
            json.fechaEmbarcado,
            json.embarcado,
            json.canibalizado,
            json.RequisicionId,
        );

        if (json.Requisicion) {
            registro.requisicion = this.requisicionDeJSON(json.Requisicion);
        }

        if (json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    // * * * Ahorrent * * * //
    public multaAhorrentDeJSON = (json: any): MultaAhorrent => {
        if (!json) return null;
        var registro = new MultaAhorrent(
            json.id,
            json.numeroTicket,
            json.fecha,
            json.motivo,
            json.direccion,
            json.pais,
            json.ciudad,
            json.moneda,
            json.monto,

            json.ContratoAhorrentId,
            json.UnidadId
        );

        if (json.fecha) {
            registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if (json.ContratoAhorrent) {
            registro.ContratoAhorrent = this.contratoAhorrentDeJSON(json.ContratoAhorrent);
        }

        if (json.Unidad) {
            registro.Unidad = this.unidadDeJSON(json.Unidad);
        }

        return registro;
    }



    public notificacionAutorizacionSolicitudDeJSON = (json: any): NotificacionAutorizacionSolicitud => {
        if (!json) return null;
        var registro = new NotificacionAutorizacionSolicitud(
            //json.nombre,
            json.correo,
            //json.codigo,
            //json.notificar,
            //json.sede,
            json.nombreCoach,
            json.nombreVendedor,
            json.correoVendedor,
        );

        return registro;
    }

    public cambioUbicacionUnidadDeJSON = (json: any): CambioUbicacionUnidad => {
        if (!json) return null;
        var registro = new CambioUbicacionUnidad(
            json.id,
            json.fecha,

            json.ubicacionActualAnterior,
            json.ubicacionActualNuevo,
            json.ubicacionAnterior,
            json.ubicacionNuevo,

            //Relaciones
            json.UsuarioId,
            json.UnidadId
        );

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
        }
        if (json.Unidad) {
            registro.Unidad = this.unidadDeJSON(json.Unidad);
        }

        if (json.fecha) {
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }



    //**ObservacionClienteAhorrent */
    public observacionClienteAhorrentDeJSON = (json: any): ObservacionClienteAhorrent => {
        if (!json) return null;
        var registro = new ObservacionClienteAhorrent(
            json.id,
            json.observaciones,
            json.fecha,
            json.UsuarioId,
            json.ClienteAhorrentId
        )

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.ClienteAhorrent) {
            registro.ClienteAhorrent = this.clienteAhorrentDeJSON(json.ClienteAhorrent);
        }

        if (json.fecha) {
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        return registro;

    }


    //**ObservacionClienteAhorrent */
    public reclamoGeneralClienteAhorrentDeJSON = (json: any): ReclamoGeneralClienteAhorrent => {
        if (!json) return null;
        var registro = new ReclamoGeneralClienteAhorrent(
            json.id,
            json.observaciones,
            json.fecha,
            json.UsuarioId,
            json.ClienteAhorrentId
        )

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.ClienteAhorrent) {
            registro.ClienteAhorrent = this.clienteAhorrentDeJSON(json.ClienteAhorrent);
        }

        if (json.fecha) {
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        return registro;

    }


    //ReclamoRentaClienteAhorrent
    public reclamoRentaClienteAhorrentDeJSON = (json: any): ReclamoRentaClienteAhorrent => {
        if (!json) return null;
        var registro = new ReclamoRentaClienteAhorrent(
            json.id,
            json.observaciones,
            json.fecha,
            json.UsuarioId,
            json.ClienteAhorrentId,
            json.ContratoAhorrentId,
            json.UnidadId
        )

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.ClienteAhorrent) {
            registro.ClienteAhorrent = this.clienteAhorrentDeJSON(json.ClienteAhorrent);
        }

        if (json.fecha) {
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if (json.ContratoAhorrent) {
            registro.ContratoAhorrent = this.contratoAhorrentDeJSON(json.ContratoAhorrent);
        }

        if (json.Unidad) {
            registro.Unidad = this.unidadDeJSON(json.Unidad);
        }

        return registro;

    }


    public ordenTallerKanboardDeJSON = (json: any): OrdenTallerKanboard => {
        if (!json) return null;
        var registro = new OrdenTallerKanboard(
            json.id,
            json.correlativo,
            json.compania,
            json.status,
            json.fechaHoraSistema,
            json.taller,
            json.codRecepcion,
            json.nombreAsesor,
            json.tipoOrden,
            json.orden,
            json.prioridad,
            json.placa,
            json.chasis,
            json.codCliente,
            json.nombreCliente,
            json.codSupervisor,
            json.nombreSupervisor,
            json.diaRecepcion,
            json.mesRecepcion,
            json.anioRecepcion,
            json.dias,
            json.codEtapa,
            json.etapa,
            json.diaPreparacion,
            json.mesPreparacion,
            json.anioPreparacion,
            json.tipo1,
            json.tipo2,
            json.modelo,
            json.serie,
            json.linea,
            json.codMarca,
            json.nombreMarca,
            json.colorVehiculo,
            json.comentarios,
            json.cono,
            //Relaciones
            json.EmpresaId,
        );

        if (json.fechaHoraSistema) {
            registro.fechaHoraSistemaFormato = this.convertirFechaATimezone(json.fechaHoraSistema, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaHoraSistemaDate = this.convertirFecha(json.fechaHoraSistema, 'YYYY-MM-DD', 'date');
        }

        if (json.Empresa) {
            registro.Empresa = this.empresaDeJSON(json.Empresa);
        }

        return registro;
    }

    public configuracionOrdenTallerKanboardDeJSON = (json: any): ConfiguracionOrdenTallerKanboard => {
        if (!json) return null;
        var registro = new ConfiguracionOrdenTallerKanboard(
            json.id,
            json.sucursales,
            json.coloresTareas,
            //Relaciones
            json.EmpresaId,
        );

        if (json.Empresa) {
            registro.Empresa = this.empresaDeJSON(json.Empresa);
        }

        return registro;
    }

    public cambioEstadoOrdenTallerKanboardDeJSON = (json: any): CambioEstadoOrdenTallerKanboard => {
        if (!json) return null;
        var registro = new CambioEstadoOrdenTallerKanboard(
            json.id,
            json.estadoAnterior,
            json.estadoPosterior,
            json.observaciones,

            json.UsuarioId,
            json.OrdenTallerKanboard,
        );

        if (json.Usuario) {
            registro.Usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.OrdenTallerKanboard) {
            registro.OrdenTallerKanboard = this.ordenTallerKanboardDeJSON(json.OrdenTallerKanboard);
        }

        if (json.estadoAnterior) {
            let index = this.indexOf(this.apiURLs.statusOrdenTallerKanboard, 'id', json.estadoAnterior);
            if (index != -1) registro.estadoAnteriorFormat = this.apiURLs.statusOrdenTallerKanboard[index].nombre;
        }

        if (json.estadoPosterior) {
            let index = this.indexOf(this.apiURLs.statusOrdenTallerKanboard, 'id', json.estadoPosterior);
            if (index != -1) registro.estadoPosteriorFormat = this.apiURLs.statusOrdenTallerKanboard[index].nombre;
        }

        return registro;
    }

    public encuestaClienteAhorrentDeJSON = (json: any): EncuestaClienteAhorrent => {
        if (!json) return null;
        var registro = new EncuestaClienteAhorrent(
            json.id,
            json.codigo,
            json.fechaEnviado,
            json.fechaLlenado,
            json.respuestas,
            json.tipoLlenado,
            json.estado,

            json.ClienteAhorrentId,
            json.ContratoAhorrentId,
        );

        if (json.fechaEnviado) {
            registro.fechaEnviadoFormato = this.convertirFecha(json.fechaEnviado, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaEnviadoDate = this.convertirFecha(json.fechaEnviado, 'YYYY-MM-DD', 'date');
        }

        if (json.fechaLlenado) {
            registro.fechaLlenadoFormato = this.convertirFecha(json.fechaLlenado, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaLlenadoDate = this.convertirFecha(json.fechaLlenado, 'YYYY-MM-DD', 'date');
        }

        if (json.ClienteAhorrent) {
            registro.ClienteAhorrent = this.clienteAhorrentDeJSON(json.ClienteAhorrent);
        }

        if (json.ContratoAhorrent) {
            registro.ContratoAhorrent = this.contratoAhorrentDeJSON(json.ContratoAhorrent);
        }

        return registro;
    }

    //Configuración Digitalización Traspaso
    public configuracionDigitalizacionTraspasosDeJSON = (json: any): ConfiguracionDigitalizacionTraspasos => {
        if (!json) return null;
        var registro = new ConfiguracionDigitalizacionTraspasos(
            json.id,
            json.tipoRecepcionUsado,
            json.sucursalesVendedor,
            json.sucursalesTraspasos,
            json.notificacionesPreOrden,
            json.notificacionesFacturacion,
            json.notificacionesDocumentacionCompleta,
            json.notificacionesEmisionCheque,
            json.notificacionesCita,
            json.abogados,
            json.prioridades,
            json.horasAbogados,
            json.restriccionHorariosAbogados,
            //Relaciones
            json.EmpresaId,
        );

        if (json.Empresa) {
            registro.Empresa = this.empresaDeJSON(json.Empresa);
        }

        return registro;
    }

    // --------------------------------------------------------------------------------

    public convertirFecha(fecha: any, formatoEntrada: string, formatoSalida: string) {
        if (formatoEntrada === 'date') fecha = moment(fecha);
        else fecha = moment(fecha, formatoEntrada);

        let res;
        if (formatoSalida === 'date') res = fecha.toDate();
        else res = fecha.format(formatoSalida);
        return res;
    }

    public convertirFechaATimezone(fecha: any, formatoSalida: string, timezone: string) {
        var fechaConvertida = momentTimezone.tz(fecha, timezone);
        return fechaConvertida.format(formatoSalida);
    }

    indexOf(array, findKey, value) {
        if (array) {
            for (let i = 0; i < array.length; i++) {
                const element = array[i];
                if (element[findKey] == value) {
                    return i;
                }
            }
        }
        return -1;
    }
}
